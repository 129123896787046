import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BottomCallout from '../../../components/BottomCallout';
import { createTablesAction, listTablesAction } from '../../../redux/order/actions';
import {getEstablishmentAction} from "../../../redux/establishment/actions";
import { motion, AnimatePresence } from 'framer-motion';
const NewTableModal = ({ isOpen, onClose }) => {
    const dispatch = useDispatch();
    const [branchId, setBranchId] = useState('');
    const [tableNumber, setTableNumber] = useState('');
    const [branches, setBranches] = useState([]);

    const tableAdd = useSelector((state) => state.createTableReducer);
    const getEstablishmentReducer = useSelector((state) => state.getEstablishmentReducer);
    const { loading, error, success } = tableAdd;
    const [isReadOnly, setIsReadOnly] = useState(false);

    useEffect(() => {
        const storedBranchId = localStorage.getItem('branchID');
        if (storedBranchId && parseInt(storedBranchId) > 0) {
            setBranchId(storedBranchId);
            setIsReadOnly(true);
        }
    }, []);

    useEffect(() => {
        dispatch(getEstablishmentAction())
    },[dispatch]);

    useEffect(() => {
        if(getEstablishmentReducer.loading == false) {
            const storedBranches = getEstablishmentReducer?.establishment.branches;
            setBranches(storedBranches);
        }
    }, [getEstablishmentReducer]);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (branchId && tableNumber) {
            dispatch(createTablesAction( {branchId, tableNumber}));
            if (!error && success) {
                onClose();
                dispatch(listTablesAction());
            }
        }
    };

    return (
        isOpen ? (
            <AnimatePresence>
                {isOpen && (
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
                    >
                        <motion.div
                            initial={{ scale: 0.9, y: 20 }}
                            animate={{ scale: 1, y: 0 }}
                            exit={{ scale: 0.9, y: 20 }}
                            className="bg-[#fff7e1] p-8 rounded-xl w-full max-w-lg shadow-2xl"
                        >
                            <h2 className="text-3xl font-bold mb-6 text-[#151e46]">Add New Table</h2>
                            <form onSubmit={handleSubmit}>
                                <div className="mb-6">
                                    <label className="block text-lg font-semibold text-[#151e46] mb-2">Branch</label>
                                    <select
                                        className= {isReadOnly ? "block w-full mt-1 p-3 border border-[#151e46] rounded-lg bg-transparent text-[#151e46] cursor-not-allowed":
                                            "block w-full mt-1 p-3 border border-[#151e46] rounded-lg bg-transparent text-[#151e46]"}
                                        value={branchId}
                                        onChange={(e) => setBranchId(e.target.value)}
                                        required
                                        disabled={isReadOnly}
                                    >
                                        <option value="" disabled>Select a branch</option>
                                        {branches.map((branch) => (
                                            <option key={branch.id} value={branch.id}>{branch.id}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="mb-6">
                                    <label className="block text-lg font-semibold text-[#151e46] mb-2">Table Number</label>
                                    <input
                                        type="number"
                                        className="block w-full mt-1 p-3 border border-[#151e46] rounded-lg bg-transparent text-[#151e46]"
                                        value={tableNumber}
                                        min="0"
                                        onChange={(e) => setTableNumber(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="flex justify-end space-x-4">
                                    <motion.button
                                        whileHover={{ scale: 1.05 }}
                                        whileTap={{ scale: 0.95 }}
                                        type="button"
                                        className="px-6 py-2 bg-[#F0532F] text-white font-semibold rounded-lg hover:bg-[#F0532F]/90 transition-colors"
                                        onClick={onClose}
                                    >
                                        Cancel
                                    </motion.button>
                                    <motion.button
                                        whileHover={{ scale: 1.05 }}
                                        whileTap={{ scale: 0.95 }}
                                        type="submit"
                                        className="px-6 py-2 bg-[#40b06d] text-white font-semibold rounded-lg hover:bg-[#40b06d]/90 transition-colors"
                                        disabled={loading}
                                    >
                                        {loading ? 'Saving...' : 'Save'}
                                    </motion.button>
                                </div>
                            </form>
                            {error && <BottomCallout message={error} variant="error" />}
                            {success && <BottomCallout message="Table added successfully!" variant="success" />}
                        </motion.div>
                    </motion.div>
                )}
            </AnimatePresence>
        ) : null
    );
};

export default NewTableModal;
