import React, {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet';
import {Button, TextInput} from "@tremor/react";
import {useDispatch, useSelector} from "react-redux";
import {forgetPasswordEmailAction} from "../../redux/auth/actions";
import BottomCallout from "../../components/BottomCallout";
import { useHistory } from 'react-router-dom';
import {motion} from "framer-motion";
import CustomButton from "../../components/CustomButton";

const ForgetPassEmail = (props) => {
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [showCallout, setShowCallout] = useState(false);

    const dispatch = useDispatch();
    const forgetPasswordEmailReducer = useSelector(state => state.forgetPasswordEmailReducer);

    const history = useHistory()

    useEffect(() => {
        if(forgetPasswordEmailReducer?.success === true) {
            setShowCallout(true);
            localStorage.setItem("forgetPassEmail",email)
            setTimeout(() => {
                setShowCallout(false);
                history.push('/forgot-pass-otp')
            }, 1000);
        }
        else if(forgetPasswordEmailReducer?.error){
            setShowCallout(true);
            setTimeout(() => {
                setShowCallout(false);
            }, 5000);
        }
    }, [forgetPasswordEmailReducer,dispatch]);

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    };

    const handleSubmit = () => {
        setError(null)
        if (!validateEmail(email)) {
            setError('Enter a valid email');
            setShowCallout(true);
            setTimeout(() => {
                setShowCallout(false);
            }, 5000);
            return;
        }
        dispatch(forgetPasswordEmailAction(email));
    };

    return (
        <div className="flex min-h-screen" style={{backgroundColor: "#FFF7E1"}}>
            <Helmet>
                <title>Forgot Password - Serv Restaurant Interface</title>
                <meta property="og:title" content="Forgot Password - Serv Restaurant Interface"/>
            </Helmet>

            {/* Left Section: Forgot Password Form */}
            <div className="w-1/2 flex items-center justify-center p-8">
                <motion.div
                    initial={{opacity: 0, y: 20}}
                    animate={{opacity: 1, y: 0}}
                    transition={{duration: 0.5}}
                    className="w-full max-w-md"
                >
                    <div className="w-full flex flex-col items-center mb-8">
                            <img
                                alt="logo"
                                src="/logos/serv-green-trimmed.png"
                                className=" w-full mb-8"
                            />
                        <h1 className="text-3xl font-bold text-[#151E46] mb-4">Forgot Your Password?</h1>
                        <p className="text-lg text-[#151E46] text-center">
                            Please enter your email address to receive a one-time password (OTP).
                        </p>
                    </div>

                    {/* Email Input */}
                    <div className="w-full mb-6">
                        <label htmlFor="Email-Input" className="block text-lg font-medium text-[#151E46] mb-2">
                            Email
                        </label>
                        <TextInput
                            type="text"
                            id="Email-Input"
                            placeholder="example@email.com"
                            className="w-full bg-[#FFF7E1] border border-[#40B06D] rounded-lg focus:ring-2 focus:ring-[#40B06D] transition-colors"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>

                    {/* Send OTP Button */}
                    <CustomButton
                        onClick={handleSubmit}
                        loading={forgetPasswordEmailReducer.loading}
                    >
                        Send OTP
                    </CustomButton>
                </motion.div>
            </div>

            {/* Right Section: Graphics and Tagline */}
            <div
                className="w-1/2 flex flex-col items-center justify-center p-8 relative"
                style={{backgroundColor: "#40B06D"}}
            >
                <img
                    alt="image"
                    src="/1%204(2)-1100h.png"
                    className="absolute top-0 right-0 w-72 object-cover"
                />
                <img
                    alt="image"
                    src="/11%202(2)-1100h.png"
                    className="absolute left-1/2 bottom-0 transform -translate-x-1/2 w-96 object-cover"
                />
                <motion.div
                    initial={{opacity: 0, y: 20}}
                    animate={{opacity: 1, y: 0}}
                    transition={{duration: 0.5, delay: 0.2}}
                    className="text-center"
                >
                    <h1 className="text-5xl font-extrabold text-[#FFF7E1] mb-4">tap, serv, eat, enjoy</h1>
                    <p className="text-xl text-[#151E46]">
                        Say goodbye to waiting and hello to a new era of dining ease with serv
                    </p>
                </motion.div>
            </div>

            {/* Callout for Errors/Success */}
            {showCallout && (error || forgetPasswordEmailReducer?.error || forgetPasswordEmailReducer?.message) && (
                <BottomCallout
                    message={error || forgetPasswordEmailReducer?.error || forgetPasswordEmailReducer?.message}
                    variant={error || forgetPasswordEmailReducer?.error ? "error" : "success"}
                />
            )}
        </div>
    );
};

export default ForgetPassEmail;
