import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Sidebar from '../../components/Sidebar';
import Table from "../../components/Table";
import FiltersModal from "../../components/FiltersModal";  // Import the FiltersModal component
import '../order/orders.css';
import { useDispatch, useSelector } from "react-redux";
import { listTransactionsAction } from '../../redux/transactions/actions';
import Skeleton from "react-loading-skeleton";
import { motion } from 'framer-motion';

const attributeNames = ['id', 'grossAmount', 'netAmount', 'orderID', 'createdAt', 'branchID', 'paymobTransactionID','processorName', 'typeName'];
const attributeDisplayNames = ['ID', 'Gross Amt', 'Net Amt', 'Order ID', 'Time', 'Branch ID','Paymob TID', 'Processor', 'Type'];
const filters = [
    { type: 'text', label: 'Transaction ID', queryString: 'id' },
    { type: 'text', label: 'Order ID', queryString: 'order_id' },
    { type: 'text', label: 'Branch ID', queryString: 'branch_id' },
    { type: 'text', label: 'Establishment ID', queryString: 'establishment_id' },
    { type: 'date-between', label: 'Created At', queryString: 'created_at' },
];

const Transactions = (props) => {
    const searchParams = new URLSearchParams(location.search);
    const orderId = searchParams.get("order_id");

    const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
    const [page, setPage] = useState(1);
    const [queryString, setQueryString] = useState(orderId ? `order_id=${orderId}` : ""); // Initialize with order_id
    const [tab, setTab] = useState('All');

    const dispatch = useDispatch();
    const listTransactionsReducer = useSelector((state) => state.listTransactionsReducer);

    useEffect(() => {
        const fetchData = () => {
            const typeName = tab === "All" ? "" : tab === "Purchase" ? "&type_name=Purchase" : tab === "Refund" ? "&type_name=Refund" : "";
            dispatch(listTransactionsAction(`${queryString}&page=${page}&max=10${typeName}`));
        };        
        fetchData();
    }, [dispatch, queryString, page, tab]);



    const handleFilterSubmit = (queryStr) => {
        setIsFilterModalOpen(false);
        setQueryString(queryStr)
        const typeName = tab === "All" ? "" : tab === "Purchase" ? "&type_name=Purchase" : tab === "Refund" ? "&type_name=Refund" : "";
        setPage(1)
        dispatch(listTransactionsAction(`${queryString}&page=${page}&max=10${typeName}`));
    };



    return (
        <div className="flex min-h-screen flex-row justify-between bg-[#fff7e1]">
            <Helmet>
                <title>Transactions - Serv</title>
                <meta
                    property="og:title"
                    content="Transactions - Serv Restaurant Interface"
                />
            </Helmet>
            <Sidebar />
            <div className="flex ml-[100px] flex-col w-full p-4">
                <div className="flex justify-between items-center my-4">
                    <h1 className="text-5xl font-bold text-[#151e46]">Transactions</h1>
                    <div className="orders-container04" onClick={() => setIsFilterModalOpen(true)}>
                        <motion.div
                            whileHover={{scale: 1.05}}
                            whileTap={{scale: 0.95}}
                            className="orders-container04 cursor-pointer" onClick={() => setIsFilterModalOpen(true)}
                        >
                            <img
                                alt="Filter"
                                src="/filter-list-svgrepo-com%20(1).svg"
                                className="orders-image"
                            />
                            <span className="orders-text01">Filter</span>
                        </motion.div>
                    </div>
                </div>
                <div className="flex justify-center">
                    {listTransactionsReducer?.loading ? (
                        <div className="w-full space-y-4">
                            <Skeleton count={10} height={60} baseColor="#f5f5dc" // light beige
                                      highlightColor="#e1e1c9"   />
                            ))
                        </div>
                    ) : (
                        <motion.div
                            initial={{opacity: 0}}
                            animate={{opacity: 1}}
                            exit={{opacity: 0}}
                            className="w-full"
                        >
                        <Table
                            tabs={['All', 'Purchase', 'Refund']}
                            idFieldName={'id'}
                            data={listTransactionsReducer?.transactions}
                            cols={attributeNames}
                            attributeDisplayNames={attributeDisplayNames}
                            colNums={9}
                            maxPage={listTransactionsReducer.countPages}
                            page={page}
                            setPage={setPage}
                            currentTab={tab}
                            setTab={setTab}
                            countRows={listTransactionsReducer.countRows}
                        />
                        </motion.div>)
                    }
                </div>
            </div>
            {isFilterModalOpen && (
                <FiltersModal
                    onClose={()=> setIsFilterModalOpen(false)}
                    title="Filter Transactions"
                    filters={filters}
                    onSubmit={handleFilterSubmit}
                    buttonLabel="Apply Filters"
                />
            )}
        </div>
    );
};

export default Transactions;
