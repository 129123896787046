import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { motion, AnimatePresence } from "framer-motion";
import { getEstablishmentAction } from "../redux/establishment/actions";
import { MdClose, MdLocationOn } from "react-icons/md";

const SelectBranchModal = ({ onClose, canClose }) => {
  const dispatch = useDispatch();
  const branchesData = useSelector((state) => state.getEstablishmentReducer);
  const [selectedBranch, setSelectedBranch] = useState(null);

  const branches = useMemo(
      () => branchesData?.establishment?.branches || [],
      [branchesData]
  );

  // Fetch branches on component mount
  useEffect(() => {
    dispatch(getEstablishmentAction());

    const intervalId = setInterval(() => {
      if (!branches?.establishment?.branches || branches.establishment.branches.length === 0) {
        dispatch(getEstablishmentAction());
      }
    }, 3000);

    return () => clearInterval(intervalId);
  }, [dispatch, branches?.establishment?.branches]);

  // Handle branch selection
  const handleBranchSelect = (branchId, name) => {
    setSelectedBranch(branchId);
    localStorage.setItem("branchID", branchId);
    localStorage.setItem("branchName", name);
    if (branches.length > 0) {
      localStorage.setItem("defaultBranchID", branches[0].id);
    }
  };

  return (
      <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
      >
        <motion.div
            initial={{scale: 0.9, y: 20}}
            animate={{scale: 1, y: 0}}
            exit={{scale: 0.9, y: 20}}
            className="bg-[#FFF7E1] rounded-xl  w-11/12 md:w-3/4 lg:w-2/3 xl:w-1/2 p-6 shadow-lg"
        >
          {/* Header */}
          <div className="flex justify-between  items-center mb-6">
            <h1 className="text-[#40B06D] text-3xl font-bold">Select Branch</h1>
            {canClose && (
                <button
                    onClick={onClose}
                    className="text-[#151E46] hover:text-[#40B06D] transition-colors"
                >
                  <MdClose className="w-8 h-8"/>
                </button>
            )}
          </div>

          {/* Subtitle */}
          <p className="text-[#151E46] text-lg font-medium mb-8">
            Choose the branch you wish to manage or view
          </p>

          {/* Branch Cards */}
          <div className="mb-6 mt-6 overflow-x-auto scrollbar-thin scrollbar-thumb-[#40B06D] scrollbar-track-[#FFF7E1]">
            <div className="flex space-x-4 h-full pb-4 pl-4 pr-4 justify-center"> {/* Added padding to left and right */}
              {branches.length > 0 ? (
                  branches.map((branch) => (
                      <motion.div
                          key={branch.id}
                          whileTap={{scale: 0.95}}
                          className={`flex-shrink-0 flex flex-col items-center bg-[#D7F1DF] rounded-xl p-6 w-56 border-4 cursor-pointer transition-all ${
                              selectedBranch === branch.id
                                  ? "border-[#40B06D] shadow-lg"
                                  : "border-transparent hover:border-[#40B06D]"
                          }`}
                          onClick={() => handleBranchSelect(branch.id, branch.address_display_as)}
                          style={{margin: "0 8px"}}
                      >
                        <MdLocationOn className="w-12 h-12 text-[#40B06D] mb-4"/>
                        <span className="text-[#151E46] text-xl font-semibold text-center">
            {branch.address_display_as}
          </span>
                      </motion.div>
                  ))
              ) : (
                  <div className="text-[#151E46] text-xl font-medium">
                    Loading branches...
                  </div>
              )}

              {/* General View Card */}
              {branches.length > 0 && (
                  <motion.div

                      whileTap={{scale: 0.95}}
                      className={`flex-shrink-0 flex flex-col items-center bg-[#D7F1DF] rounded-xl p-6 w-56 border-4 cursor-pointer transition-all ${
                          selectedBranch === 0
                              ? "border-[#40B06D] shadow-lg"
                              : "border-transparent hover:border-[#40B06D]"
                      }`}
                      onClick={() => handleBranchSelect(0)}
                      style={{margin: "0 8px"}}
                  >
                    <MdLocationOn className="w-12 h-12 text-[#40B06D] mb-4"/>
                    <span className="text-[#151E46] text-xl font-semibold text-center">
          General View
        </span>
                  </motion.div>
              )}
            </div>
          </div>

          {/* Confirm Button */}
          <div className="flex justify-end">
            <button
                role="button"
                disabled={branches.length === 0}
                onClick={onClose}
                className={`px-8 py-3 text-xl font-semibold rounded-full transition-all ${
                    branches.length === 0
                        ? "bg-gray-400 text-gray-200 cursor-not-allowed"
                        : "bg-[#40B06D] text-[#FFF7E1] hover:bg-[#40B06D]/90"
                }`}
            >
              Confirm
            </button>
          </div>
        </motion.div>
      </motion.div>
  );
};

export default SelectBranchModal;