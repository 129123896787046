import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Sidebar from '../../components/Sidebar';
import Table from "../../components/Table";
import FiltersModal from "../../components/FiltersModal";
import './orders.css';
import { useDispatch, useSelector } from "react-redux";
import { listOrdersAction } from "../../redux/order/actions";
import { BASE_URL } from '../../config';
import Skeleton from "react-loading-skeleton";
import { motion } from 'framer-motion';

const attributeNames = ['orderID', 'number', 'tableNumber', 'typeDisplayName', 'totalPaid', 'total', 'createdAt'];
const attributeDisplayNames = ['ID', 'Number', 'Table', 'Type', 'Total Paid Online', 'Total', 'Time', 'View'];
const filters = [
    { type: 'text', label: 'Order ID', queryString: 'order_id' },
    { type: 'text', label: 'Table Number', queryString: 'table_id' },
    { type: 'date-between', label: 'Created At', queryString: 'created_at' },
];

const Orders = (props) => {
    const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
    const [page, setPage] = useState(1);
    const [queryString, setQueryString] = useState('');
    const [tab, setTab] = useState('All');
    const [trigger, setTrigger] = useState(0);

    const dispatch = useDispatch();
    const listOrdersReducer = useSelector((state) => state.listOrdersReducer);

    useEffect(() => {
        const fetchData = () => {
            const statusName = tab === "All" ? "" : tab === "Completed" ? "&status_name=completed" : "&status_name=in_progress";
            dispatch(listOrdersAction(`${queryString}&page=${page}&max=10${statusName}&max=10`));
        };
        fetchData();
    }, [dispatch, trigger, queryString, page, tab]);

    useEffect(() => {
        const eventSource = new EventSource(`${BASE_URL}/orders/events`, { withCredentials: true });

        eventSource.addEventListener("OrdersUpdated", () => {
            setTimeout(() => {
                setTrigger((prev) => prev + 1);
            }, 2000);
        });

        eventSource.addEventListener("connected", (event) => {
            console.log("Connected to SSE:", event.data);
        });

        return () => {
            eventSource.close();
        };
    }, []);

    const handleFilterSubmit = (queryStr) => {
        setIsFilterModalOpen(false);
        setQueryString(queryStr);
        const statusName = tab === "All" ? "" : tab === "Completed" ? "&status_name=completed" : "&status_name=in_progress";
        setPage(1);
        dispatch(listOrdersAction(`${queryString}&page=${page}&max=10${statusName}&max=10`));
    };

    return (
        <div className="flex min-h-screen flex-row justify-between bg-[#fff7e1]">
            <Helmet>
                <title>Orders - Serv</title>
                <meta property="og:title" content="Orders - Serv Restaurant Interface"/>
            </Helmet>
            <Sidebar/>
            <div className="flex flex-col w-full ml-[100px]">
                <div className="flex justify-between items-center my-4 mx-4">
                    <h1 className="text-5xl font-bold text-[#151e46]">Orders</h1>
                    <motion.div
                        whileHover={{scale: 1.05}}
                        whileTap={{scale: 0.95}}
                        className="orders-container04 cursor-pointer"
                        onClick={() => setIsFilterModalOpen(true)}
                    >
                        <img
                            alt="Filter"
                            src="/filter-list-svgrepo-com%20(1).svg"
                            className="orders-image"
                        />
                        <span className="orders-text01">Filter</span>
                    </motion.div>
                </div>

                <div className="flex justify-center">
                    {listOrdersReducer?.loading ? (
                        <div className="w-full space-y-4">
                                <Skeleton count={10} height={60} baseColor="#f5f5dc" // light beige
                                          highlightColor="#e1e1c9"   />
                            ))
                        </div>
                    ) : (
                        <motion.div
                            initial={{opacity: 0}}
                            animate={{opacity: 1}}
                            exit={{opacity: 0}}
                            className="w-full"
                        >
                            <Table
                                tabs={['All', 'In Progress', 'Completed']}
                                baseRoute={'/orders'}
                                idFieldName={'orderID'}
                                data={listOrdersReducer?.orders}
                                cols={attributeNames}
                                attributeDisplayNames={attributeDisplayNames}
                                colNums={8}
                                maxPage={listOrdersReducer.countPages}
                                page={page}
                                setPage={setPage}
                                currentTab={tab}
                                setTab={setTab}
                                countRows={listOrdersReducer.countRows}
                            />
                        </motion.div>
                    )}
                </div>
            </div>

            {isFilterModalOpen && (
                <FiltersModal
                    onClose={()=> setIsFilterModalOpen(false)}
                    title="Filter Orders"
                    filters={filters}
                    onSubmit={handleFilterSubmit}
                    buttonLabel="Apply Filters"
                />
            )}
        </div>
    );
};

export default Orders;
