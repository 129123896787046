import React, { useState } from 'react';
import {Button} from "@tremor/react";
import {useDispatch, useSelector} from "react-redux";
import BottomCallout from "./BottomCallout";
import {createFeedbackAction} from "../redux/feedback/actions";
import { motion, AnimatePresence } from "framer-motion";
import { MdClose, MdStar, MdStarBorder } from "react-icons/md";

const FeedbackModal = ({onClose, setShowCallout}) => {
  const [rating, setRating] = useState(0);
  const [feedbackText, setFeedbackText] = useState('');

  const dispatch = useDispatch();
  const createFeedbackReducer = useSelector((state) => state.createFeedbackReducer);
  const { loading } = createFeedbackReducer;

  const handleStarClick = (index) => {
    setRating(index + 1);
  };
  const handleTextareaChange = (event) => {
    setFeedbackText(event.target.value);
  };

  const handleSubmit = () => {
      dispatch(createFeedbackAction({ rating, comment: feedbackText }));
      setShowCallout(true);
      onClose(false)

  };

  return (
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-[#151E46]/80 backdrop-blur-sm">
        <motion.div
            initial={{scale: 0.95, opacity: 0}}
            animate={{scale: 1, opacity: 1}}
            exit={{scale: 0.95, opacity: 0}}
            className="relative w-full max-w-2xl bg-[#FFF7E1] rounded-xl shadow-lg p-8 mx-4"
        >
          {/* Close Button */}
          <button
              onClick={ () => onClose()}
              className="absolute top-4 right-4 text-[#40B06D] hover:text-[#151E46] transition-colors"
          >
            <MdClose className="w-8 h-8"/>
          </button>

          {/* Content */}
          <div className="flex flex-col items-center space-y-6">
            {/* Header */}
            <div className="text-center space-y-2">
              <h2 className="text-4xl font-bold text-[#40B06D]">
                We Value Your Feedback
              </h2>
              <p className="text-xl text-[#151E46]/90">
                Please share your thoughts with us to help improve your experience
              </p>
            </div>

            {/* Stars Rating */}
            <div className="flex space-x-2">
              {[...Array(5)].map((_, index) => (
                  <motion.button
                      key={index}
                      whileHover={{scale: 1.1}}
                      whileTap={{scale: 0.9}}
                      className="text-[#40B06D] hover:text-[#40b060] transition-colors"
                      onClick={() => handleStarClick(index)}
                  >
                    {index < rating ? (
                        <MdStar className="w-12 h-12"/>
                    ) : (
                        <MdStarBorder className="w-12 h-12"/>
                    )}
                  </motion.button>
              ))}
            </div>

            {/* Feedback Textarea */}
            <div className="w-full">
              <motion.textarea
                  value={feedbackText}
                  onChange={handleTextareaChange}
                  placeholder="Please describe your experience or suggestions in detail here..."
                  className="w-full h-48 p-4 text-[#151E46] bg-[#FFF7E1] border-2 border-[#40B06D] rounded-xl focus:outline-none focus:ring-2 focus:ring-[#40B06D] transition-all"
                  whileFocus={{scale: 1.02}}
              />
            </div>

            {/* Submit Button */}
            <motion.button
                onClick={handleSubmit}
                whileHover={{scale: 1.05}}
                whileTap={{scale: 0.95}}
                disabled={loading}
                className="px-8 py-3 text-xl font-semibold text-[#FFF7E1] bg-[#40B06D] rounded-xl hover:bg-[#151E46] transition-colors"
            >
              {loading ? "Submitting..." : "Submit"}
            </motion.button>
          </div>
        </motion.div>
      </div>
  );
};

export default FeedbackModal;
