import React, {useEffect, useState} from 'react';
import { Helmet } from 'react-helmet';
import {useDispatch, useSelector} from "react-redux";
import {isLoggedInAction, loginAction} from "../../redux/auth/actions";
import BottomCallout from "../../components/BottomCallout";
import {Button, TextInput} from "@tremor/react";
import {Link, useHistory} from "react-router-dom";
import SelectBranchModal from "../../components/SelectBranchModal";
import {getEstablishmentAction, getEstablishmentBranchAction} from "../../redux/establishment/actions";
import {motion, AnimatePresence} from "framer-motion";
import CustomButton from "../../components/CustomButton";

const Login = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [showCallout, setShowCallout] = useState(false);
    const [showChooseBranchModal, setShowChooseBranchModal] = useState(false)
    const [isLoggedInEndpointCalled, setIsLoggedInEndpointCalled] = useState(false);

    const loginReducer = useSelector(state => state.loginReducer);
    const isLoggedInReducer = useSelector(state => state.isLoggedInReducer);
    const establishmentBranch = useSelector(state => state.getEstablishmentBranchReducer.establishmentBranch);

    useEffect(() => {
        if (loginReducer.error || loginReducer.isAuthenticated) {
            setShowCallout(true);

            if(loginReducer.isAuthenticated) {
                if (loginReducer?.user?.branchID == null) {
                    dispatch(getEstablishmentAction());
                    setShowChooseBranchModal(true)
                } else {
                    localStorage.setItem('branchID',loginReducer.user.branchID)
                    dispatch(getEstablishmentBranchAction())
                }
            }
            setTimeout(() => {
                setShowCallout(false);
            }, 3000);
        }
    }, [loginReducer]);

    useEffect(() => {
        if(establishmentBranch) {
            localStorage.setItem('branchName', establishmentBranch?.address_display_as)
            setTimeout(() => {
                history.push('/dashboard')
                setShowCallout(false);
            }, 3000);
        }
    }, [establishmentBranch]);

    useEffect(() => {
            dispatch(isLoggedInAction());
            setIsLoggedInEndpointCalled(true);
    },[dispatch]);


    useEffect(() => {
        if (isLoggedInEndpointCalled && !isLoggedInReducer.loading) {
            if (isLoggedInReducer.isAuthorised && localStorage.getItem('userInfo')) {
                history.push('/dashboard');
            }
        }
    }, [isLoggedInReducer.isAuthorised, isLoggedInReducer.loading, isLoggedInEndpointCalled, location.pathname, history]);

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    };

    const handleSubmit = () => {
        setError(null)
        if (!email || !password) {
            setError('Both fields are required');
            setShowCallout(true);
            setTimeout(() => {
                setShowCallout(false);
            }, 5000);
            return;

        }
        if (!validateEmail(email)) {
            setError('Enter a valid email');
            setShowCallout(true);
            setTimeout(() => {
                setShowCallout(false);
            }, 5000);
            return;
        }
        dispatch(loginAction(email, password));
    };

  return (
      <div className="flex min-h-screen" style={{backgroundColor: "#FFF7E1"}}>
          <Helmet>
              <title>Serv Restaurant Interface</title>
              <meta property="og:title" content="Serv Restaurant Interface"/>
          </Helmet>

          {/* Left Section: Login Form */}
          <div className="w-1/2 flex items-center justify-center p-8">
              <motion.div
                  initial={{opacity: 0, y: 20}}
                  animate={{opacity: 1, y: 0}}
                  transition={{duration: 0.5}}
                  className="w-full max-w-md"
              >
                  <div className="w-full flex flex-col items-center mb-8">
                      <img
                          alt="logo"
                          src="/logos/serv-green-trimmed.png"
                          className=" w-full mb-8"
                      />
                      <span className="text-2xl font-bold text-[#151E46]">Login to your account</span>
                  </div>

                  {/* Email Input */}
                  <div className="w-full mb-6">
                      <label htmlFor="Email-Input" className="block text-lg font-medium text-[#151E46] mb-2">
                          Email
                      </label>
                      <TextInput
                          type="text"
                          id="Email-Input"
                          placeholder="example@email.com"
                          className="w-full bg-[#FFF7E1] border border-[#151E46] focus:border-[#40b06d] focus:border-2 rounded-lg transition-colors focus:outline-none !important"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                      />
                  </div>

                  {/* Password Input */}
                  <div className="w-full mb-6">
                      <label htmlFor="Password-Input" className="block text-lg font-medium text-[#151E46] mb-2">
                          Password
                      </label>
                      <TextInput
                          type="password"
                          id="Password-Input"
                          placeholder="********"
                          className="w-full bg-[#FFF7E1] border border-[#151E46] focus:border-[#40b06d] rounded-lg focus:ring-2 focus:ring-[#40B06D] transition-colors"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                      />
                  </div>

                  {/* Forgot Password Link */}
                  <Link to="/forgot-pass-email" className="block text-lg text-[#F0532F] hover:underline mb-6">
                      Forgot Password?
                  </Link>

                  {/* Login Button */}
                  <CustomButton
                      className="w-full py-3 bg-[#40B06D] text-white text-lg font-semibold rounded-lg hover:bg-[#40B06D]/90 transition-colors"
                      onClick={handleSubmit}
                      loading={loginReducer.loading}
                  >
                      Login
                  </CustomButton>
              </motion.div>
          </div>

          {/* Right Section: Graphics and Tagline */}
          <div
              className="w-1/2 flex flex-col items-center justify-center p-8 relative"
              style={{backgroundColor: "#40B06D"}}
          >
              <img
                  alt="image"
                  src="/1%204(2)-1100h.png"
                  className="absolute top-0 right-0 w-72 object-cover"
              />
              <img
                  alt="image"
                  src="/11%202(2)-1100h.png"
                  className="absolute left-1/2 bottom-0 transform -translate-x-1/2 w-96 object-cover"
              />
              <motion.div
                  initial={{opacity: 0, y: 20}}
                  animate={{opacity: 1, y: 0}}
                  transition={{duration: 0.5, delay: 0.2}}
                  className="text-center"
              >
                  <h1 className="text-5xl font-extrabold text-[#FFF7E1] mb-4">tap, serv, eat, enjoy</h1>
                  <p className="text-xl text-[#151E46]">
                      Say goodbye to waiting and hello to a new era of dining ease with serv
                  </p>
              </motion.div>
          </div>

          {/* Callout for Errors/Success */}
          {showCallout && (error || loginReducer.error || loginReducer.message) && (
              <BottomCallout
                  message={error || loginReducer.error || loginReducer.message}
                  variant={error || loginReducer.error ? "error" : "success"}
              />
          )}

          {/* Branch Selection Modal */}
          {showChooseBranchModal && (
              <SelectBranchModal
                  onClose={() => {
                      setShowChooseBranchModal(false);
                      history.push("/dashboard");
                  }}
              />
          )}
      </div>
  );
};

export default Login;
