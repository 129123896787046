import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Sidebar from '../../components/Sidebar';
import Table from "../../components/Table";
import FiltersModal from "../../components/FiltersModal";
import './orders.css';
import { useDispatch, useSelector } from "react-redux";
import { downloadTableQRCodeAction, listTablesAction } from "../../redux/order/actions";
import NewTableModal from "./components/NewTableModal";
import { motion, AnimatePresence } from "framer-motion";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const filters = [
    { type: 'text', label: 'Table Number', queryString: 'table_number' },
    { type: 'text', label: 'Order ID', queryString: 'order_id' },
];

const attributeNames = ['tableNumber', 'orderId', 'orderNumber', 'statusDisplayName'];
const attributeDisplayNames = ['Table', 'Order ID', 'Order Number', 'Status', 'View', 'Action'];

const Tables = (props) => {
    const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
    const [page, setPage] = useState(1);
    const [queryString, setQueryString] = useState('');
    const [isNewTableModalOpen, setIsNewTableModalOpen] = useState(false);
    const [tab, setTab] = useState('All');

    const dispatch = useDispatch();
    const listTablesReducer = useSelector((state) => state.listTablesReducer);
    const createTableReducer = useSelector((state) => state.createTableReducer);

    useEffect(() => {
        const fetchData = () => {
            const statusName = tab === "All" ? "" : tab === "Available" ? "&status_name=available" : "&status_name=in_progress";
            dispatch(listTablesAction(`${queryString}&page=${page}&max=10${statusName}&sort=-order_id`));
        };
        fetchData();
        const intervalId = setInterval(fetchData, 30000);
        return () => clearInterval(intervalId);
    }, [dispatch, queryString, page, tab, createTableReducer]);

    useEffect(() => {
        if (createTableReducer.success) setIsNewTableModalOpen(false);
    }, [createTableReducer]);

    const handleFilterSubmit = (queryStr) => {
        setIsFilterModalOpen(false);
        setQueryString(queryStr);
        const statusName = tab === "All" ? "" : tab === "available" ? "&status_name=available" : "&status_name=in_progress";
        setPage(1);
        dispatch(listTablesAction(`${queryString}&page=${page}&max=10${statusName}&sort=-order_id`));
    };

    return (
        <div className="flex min-h-screen flex-row justify-between bg-[#fff7e1]">

            <Helmet>
                <title>Tables - Serv</title>
                <meta property="og:title" content="Orders - Serv Restaurant Interface"/>
            </Helmet>
            <Sidebar/>
            <div className="flex flex-col w-full ml-[100px]">
                <div className="flex justify-between items-center my-4">
                    <h1 className="text-5xl font-bold text-[#151e46]">Tables</h1>
                    <div className="flex px-4 gap-4">
                        <motion.div
                            whileHover={{scale: 1.05}}
                            whileTap={{scale: 0.95}}
                            onClick={() => setIsNewTableModalOpen(true)}
                            className="flex items-center justify-center bg-[#151e46] rounded-2xl px-6 py-3 cursor-pointer"
                        >
                            <img alt="New Table" src="/new-tab-svgrepo-com.svg" className="w-9 object-cover mr-2"/>
                            <button className="text-[#fff7e1] text-xl">New</button>
                        </motion.div>
                        {localStorage.getItem('branchID') && localStorage.getItem('branchID') !== '0' &&
                            <motion.div
                                whileHover={{scale: 1.05}}
                                whileTap={{scale: 0.95}}
                                onClick={() => dispatch(downloadTableQRCodeAction({
                                    tableID: '0',
                                    branchID: localStorage.getItem('branchID')
                                }))}
                                className="flex items-center justify-center bg-[#151e46] rounded-2xl px-6 py-3 cursor-pointer"
                            >
                                <button className="text-[#fff7e1] text-xl">Pickup QR</button>
                            </motion.div>
                        }
                        <motion.div
                            whileHover={{scale: 1.03}}
                            whileTap={{scale: 0.95}}
                            className="orders-container04" onClick={() => setIsFilterModalOpen(true)}
                        >
                            <img alt="Filter" src="/filter-list-svgrepo-com%20(1).svg" className="orders-image"/>
                            <span className="orders-text01">Filter</span>
                        </motion.div>
                    </div>
                </div>
                <div className="flex justify-center">
                    {listTablesReducer.loading ? (
                        <Skeleton baseColor="#f5f5dc" // light beige
                                  highlightColor="#e1e1c9" count={10} height={50}/>
                    ) : (
                        <AnimatePresence>
                            <motion.div
                                initial={{opacity: 0}}
                                animate={{opacity: 1}}
                                exit={{opacity: 0}}
                                className="w-full"
                            >
                                <Table
                                    tabs={['All', 'In Progress', 'Available']}
                                    data={listTablesReducer?.tables}
                                    cols={attributeNames}
                                    attributeDisplayNames={attributeDisplayNames}
                                    colNums={6}
                                    baseRoute={'/orders'}
                                    idFieldName={'orderId'}
                                    page={page}
                                    setPage={setPage}
                                    setTab={setTab}
                                    currentTab={tab}
                                    maxPage={listTablesReducer.countPages}
                                    countRows={listTablesReducer.countRows}
                                    qr={true}
                                    onRowClick={(tableID, branchID) => dispatch(downloadTableQRCodeAction({
                                        tableID,
                                        branchID
                                    }))}
                                />
                            </motion.div>
                        </AnimatePresence>
                    )}
                </div>
            </div>
            {isFilterModalOpen && (
                <FiltersModal
                    onClose={()=> setIsFilterModalOpen(false)}
                    title="Filter Tables"
                    filters={filters}
                    onSubmit={handleFilterSubmit}
                    buttonLabel="Apply Filters"
                />
            )}
            {isNewTableModalOpen && (
                <NewTableModal
                    isOpen={isNewTableModalOpen}
                    onClose={() => setIsNewTableModalOpen(false)}
                />
            )}
        </div>
    );
};

export default Tables;