import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import {buildQueryString} from "../utils/queryStringBuilder";
import {AnimatePresence, motion} from "framer-motion";
import {MdClose} from "react-icons/md";

const FiltersModal = ({ title, subtitle, filters, onSubmit, buttonLabel, onClose }) => {
    const [filterValues, setFilterValues] = useState({});

    const handleInputChange = (name, value) => {
        setFilterValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };

    const renderFilterField = (filter) => {
        switch (filter.type) {
            case 'text':
                return (
                    <input
                        type="text"
                        placeholder={filter.label}
                        className="p-2 border rounded text-[#151e46]"
                        value={filterValues[filter.queryString] || ''}
                        onChange={(e) => handleInputChange(filter.queryString, e.target.value)}
                    />
                );
            case 'dropdown':
                return (
                    <select
                        className="p-2 bg-[#fff7e1] text-[#151e46] border rounded"
                        value={filterValues[filter.queryString] || ''}
                        onChange={(e) => handleInputChange(filter.queryString, e.target.value)}
                    >
                        {filter?.options?.map((option, index) => (
                            <option key={index} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </select>
                );
            case 'date':
                return (
                    <input
                        type="date"
                        className="p-2 border text-[#151e46] rounded"
                        value={filterValues[filter.queryString] || ''}
                        onChange={(e) => handleInputChange(filter.queryString, e.target.value)}
                    />
                );
            case 'date-between':
                return (
                    <div className="flex space-x-2">
                        <input
                            type="date"
                            className="p-2 border text-[#151e46] rounded"
                            placeholder="Start Date"
                            value={filterValues[`${filter.queryString}[gte]`] || ''}
                            onChange={(e) => handleInputChange(`${filter.queryString}[gte]`, e.target.value)}
                        />
                        <input
                            type="date"
                            className="p-2 border text-[#151e46] rounded"
                            placeholder="End Date"
                            value={filterValues[`${filter.queryString}[lte]`] || ''}
                            onChange={(e) => handleInputChange(`${filter.queryString}[lte]`, e.target.value)}
                        />
                    </div>
                );
            default:
                return null;
        }
    };
    const handleClearFilters = () => {
        setFilterValues({});
        const queryString = buildQueryString(filterValues);
        onSubmit(queryString);
    };
    const handleFilterSubmit = () => {
        const queryString = buildQueryString(filterValues);
        onSubmit(queryString);
    };

    return (
        <AnimatePresence>
            <motion.div
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                className="fixed z-50 bg-[#FFF7E1] border border-[#40B06D] rounded-lg shadow-lg p-6"
                style={{
                    top: "8%", // Adjust based on where you want it to appear
                    left: "75%", // Adjust based on where you want it to appear
                    transform: "translateX(-50%)",
                }}
            >
                {/* Header */}
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-[#40B06D] text-2xl font-bold">{title}</h2>
                    <button
                        onClick={onClose}
                        className="text-[#151E46] hover:text-[#40B06D] transition-colors"
                    >
                        <MdClose className="w-6 h-6" />
                    </button>
                </div>

                {/* Subtitle */}
                {subtitle && (
                    <p className="text-[#151E46] text-lg mb-6">{subtitle}</p>
                )}

                {/* Filters Grid */}
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                    {filters?.map((filter, index) => (
                        <div key={index} className="flex flex-col space-y-2">
                            <label className="text-[#40B06D] font-medium">{filter.label}</label>
                            {renderFilterField(filter)}
                        </div>
                    ))}
                </div>

                {/* Buttons */}
                <div className="flex justify-end mt-6 space-x-4">
                    <button
                        onClick={handleClearFilters}
                        className="px-6 py-2 bg-[#F0532F] text-white font-semibold rounded-lg hover:bg-[#F0532F]/90 transition-colors"
                    >
                        Clear Filters
                    </button>
                    <button
                        onClick={handleFilterSubmit}
                        className="px-6 py-2 bg-[#40B06D] text-white font-semibold rounded-lg hover:bg-[#40B06D]/90 transition-colors"
                    >
                        {buttonLabel}
                    </button>
                </div>
            </motion.div>
        </AnimatePresence>
    );
};

FiltersModal.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    filters: PropTypes.arrayOf(
        PropTypes.shape({
            type: PropTypes.oneOf(['text', 'dropdown', 'date', 'date-between']).isRequired,
            label: PropTypes.string.isRequired,
            queryString: PropTypes.string.isRequired,
            options: PropTypes.arrayOf(
                PropTypes.shape({
                    value: PropTypes.string.isRequired,
                    label: PropTypes.string.isRequired,
                })
            ),
        })
    ).isRequired,
    onSubmit: PropTypes.func.isRequired,
    buttonLabel: PropTypes.string.isRequired,
};

FiltersModal.defaultProps = {
    subtitle: '',
};

export default FiltersModal;
