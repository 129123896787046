import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Button } from "@tremor/react";
import { useDispatch, useSelector } from "react-redux";
import { forgetPasswordEmailAction, verifyOTPAction } from "../../redux/auth/actions";
import BottomCallout from "../../components/BottomCallout";
import { useHistory } from 'react-router-dom';
import CustomButton from "../../components/CustomButton";
import {AnimatePresence, motion} from "framer-motion";

const ForgetPassOTP = (props) => {
    const [otp, setOtp] = useState(new Array(6).fill(""));
    const [error, setError] = useState('');
    const [showCallout, setShowCallout] = useState(false);
    const [resendTimer, setResendTimer] = useState(60);

    const dispatch = useDispatch();
    const forgetPasswordEmailReducer = useSelector(state => state.forgetPasswordEmailReducer);
    const verifyOTPReducer = useSelector(state => state.verifyOTPReducer);

    const history = useHistory();

    useEffect(() => {
        if (forgetPasswordEmailReducer?.success) {
            setShowCallout(true);
            setTimeout(() => {
                setShowCallout(false);
                // history.push('/reset-password');
            }, 2000);
        } else if (forgetPasswordEmailReducer?.error) {
            setShowCallout(true);
            setTimeout(() => {
                setShowCallout(false);
            }, 5000);
        }
    }, [forgetPasswordEmailReducer, dispatch]);

    useEffect(() => {
        if (verifyOTPReducer?.success) {
            setShowCallout(true);
            setTimeout(() => {
                setShowCallout(false);
                history.push('/reset-pass');
            }, 2000);
        } else if (verifyOTPReducer?.error) {
            setShowCallout(true);
            setTimeout(() => {
                setShowCallout(false);
            }, 5000);
        }
    }, [verifyOTPReducer, dispatch]);

    useEffect(() => {
        let timer;
        if (resendTimer > 0) {
            timer = setTimeout(() => setResendTimer(resendTimer - 1), 1000);
        }
        return () => clearTimeout(timer);
    }, [resendTimer]);

    useEffect(() => {
        // If user didn't pass through the otp process
        if(!forgetPasswordEmailReducer.id) {
            history.push('/forgot-pass-email')
        }
    });

    const handleOtpChange = (element, index) => {
        if (/^[0-9]$/.test(element.value)) {
            const newOtp = [...otp];
            newOtp[index] = element.value;
            setOtp(newOtp);

            // Focus the next input if it's not the last one
            if (index < otp.length - 1) {
                element.nextElementSibling.focus();
            }
        } else {
            element.value = "";
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === "Backspace") {
            if (otp[index] !== "") {
                const newOtp = [...otp];
                newOtp[index] = "";
                setOtp(newOtp);
            } else if (index > 0) {
                e.target.previousElementSibling.focus();
            }
        } else if (e.key === "ArrowRight" && index < otp.length - 1) {
            e.target.nextElementSibling.focus();
        } else if (e.key === "ArrowLeft" && index > 0) {
            e.target.previousElementSibling.focus();
        }
    };


    const handleSubmit = () => {
        setError(null);
        if (otp.join("").length !== 6) {
            setError('Please enter a valid 6-digit OTP');
            setShowCallout(true);
            setTimeout(() => {
                setShowCallout(false);
            }, 5000);
            return;
        }
        dispatch(verifyOTPAction(forgetPasswordEmailReducer.id, otp.join("")));
    };

    const handleResend = () => {
        setResendTimer(60);
        const email = localStorage.getItem('forgetPassEmail');
        dispatch(forgetPasswordEmailAction(email));
    };

    return (
        <div className="flex overflow-auto min-h-screen items-center" style={{ backgroundColor: '#fff7e1' }}>
            <Helmet>
                <title>Serv Restaurant Interface</title>
                <meta property="og:title" content="Serv Restaurant Interface" />
            </Helmet>
            <AnimatePresence >
            <div className="w-1/2 flex items-center flex-col justify-start rounded-lg p-8" style={{ backgroundColor: '#fff7e1' }}>
                <div className="w-full flex items-start mb-8"></div>
                <div className='w-full flex flex-col items-center' style={{alignSelf: "center"}}>
                    <motion.div
                        initial={{opacity: 0, y: 0}}
                        animate={{opacity: 1, y: 0}}
                        transition={{duration: 0.5}}
                        className="w-full max-w-md"
                    >
                        <div className="w-full flex flex-col items-center mb-8">
                            <img
                                alt="logo"
                                src="/logos/serv-green-trimmed.png"
                                className=" w-full mb-8"
                            />
                            <span className="text-gray-900 text-2xl font-bold mb-4">Forgot Your Password ?</span>
                            <span className="text-gray-900 text-md text-center">We've sent a verification code to your email. <br/> Please enter the code below to continue.</span>

                        </div>

                        <div className="w-full flex flex-col items-center relative">
                            <div className="forgot-pass-otp-container4">
                                <div className="w-full flex flex-col items-center relative">
                                <div className="flex justify-center items-center space-x-2">
                                        {otp.map((data, index) => (
                                            <input
                                                key={index}
                                                type="text"
                                                maxLength="1"
                                                className="otp-input text-center bg-transparent w-12 h-12 border border-gray-300 rounded-md focus:outline-none focus:border[#40b06d]"
                                                value={data}
                                                onChange={e => handleOtpChange(e.target, index)}
                                                onFocus={e => e.target.select()}
                                                onKeyDown={e => handleKeyDown(e, index)}
                                            />
                                        ))}
                                    </div>

                                    <div className="w-full max-w-md h-16 flex flex-col mt-4">
                                <span className="text-lg text-center">Didn&apos;t get a code yet? {resendTimer > 0 ? (
                                    <span className="text-lg text-center"
                                          style={{color: '#F0532F'}}>Resend in {resendTimer}s</span>
                                ) : (
                                    <span className="text-lg text-center underline cursor-pointer"
                                          style={{color: '#F0532F'}} onClick={handleResend}>Resend code</span>
                                )}
                                </span>
                                    </div>
                                </div>
                                <CustomButton
                                    className="mt-8 px-6 py-2 w-[150px] h-[50px] text-white rounded-lg"
                                    style={{backgroundColor: '#F0532F'}}
                                    onClick={handleSubmit}
                                    loading={verifyOTPReducer?.loading}
                                >
                                    Submit
                                </CustomButton>
                            </div>
                        </div>
                    </motion.div>
                </div>
            </div>
            <div className="w-1/2 h-screen flex items-center flex-col justify-center"
                 style={{backgroundColor: '#40b06d'}}>
                <img alt="image" src="/1%204(2)-1100h.png" className="absolute top-0 right-0 w-72 object-cover"/>
                <img alt="image" src="/11%202(2)-1100h.png"
                     className="absolute left-1/2 bottom-0 transform -translate-x-1/2 w-96 object-cover"/>
                <span className="text-[#fff7e1] text-5xl font-extrabold text-center mt-4 mb-4">tap, serv, eat, enjoy</span>
                <span className="text-gray-900 text-xl font-medium text-center whitespace-pre-line"
                      style={{color: "#151e46"}}>
                    Say goodbye to waiting and hello to a new era of dining ease with serv
                </span>
            </div>
            </AnimatePresence>
            {showCallout && (error || forgetPasswordEmailReducer?.error || forgetPasswordEmailReducer?.message) && (
                <BottomCallout
                    message={error || forgetPasswordEmailReducer?.error || forgetPasswordEmailReducer?.message}
                    variant={error || forgetPasswordEmailReducer?.error ? "error" : "success"}
                />
            )}
        </div>
    );
};

export default ForgetPassOTP;
