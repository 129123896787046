import React, { useState } from "react";
import PropTypes from "prop-types";
import FeedbackModal from "./FeedbackModal";
import { useDispatch, useSelector } from "react-redux";
import BottomCallout from "./BottomCallout";
import { Link } from "react-router-dom";
import { logoutAction } from "../redux/auth/actions";
import SelectBranchModal from "./SelectBranchModal";
import { FaBars, FaTimes } from "react-icons/fa";

const Sidebar = (props) => {
    const [activeLink, setActiveLink] = useState("");
    const [feedbackModal, setFeedbackModal] = useState(false);
    const [showCallout, setShowCallout] = useState(false);
    const [branchModal, setBranchModal] = useState(false);
    const [isCollapsed, setIsCollapsed] = useState(true); // Collapsible state
    const createFeedbackReducer = useSelector((state) => state.createFeedbackReducer);
    const { error, message } = createFeedbackReducer;
    const dispatch = useDispatch();

    const handleLinkClick = (link) => setActiveLink(link);

    const handleLogout = () => {
        dispatch(logoutAction());
        localStorage.clear();
    };

    const onBranchModalClose = () => {
        setBranchModal(false);
        window.location.reload();
    };

    const linkClass = (link) =>
        `flex items-center text-lg font-medium p-3 rounded-lg transition-all duration-300 ${
            activeLink === link
                ? "text-white bg-[#40b06d] hover:bg-[#40b06d]/90"
                : "text-gray-300 hover:bg-[#2a3655] hover:text-white"
        }`;

    const currentBranch =
        localStorage.getItem("branchName") !== "undefined"
            ? localStorage.getItem("branchName")
            : "Select Branch";

    return (
        <div className="relative z-10">
            {/* Sidebar */}
            <div
                className={`fixed h-screen top-0 left-0 flex flex-col bg-[#151e46] shadow-lg transition-all duration-300 ${
                    isCollapsed ? "w-20" : "w-64"
                }`}
            >
                {/* Logo Section */}
                <div className="w-full mt-8 h-16 flex items-center justify-center">
                    <img
                        alt="logo"
                        src="/logos/serv-green.png"
                        className={`transition-all duration-300 ${isCollapsed ? "h-10" : "h-12"}`}
                    />
                </div>

                {/* Collapse Toggle Button */}
                <button
                    onClick={() => setIsCollapsed(!isCollapsed)}
                    className={`mx-5 p-3 rounded-lg text-[#fff7e1] hover:bg-[#2a3655] transition-all duration-300 ${
                        isCollapsed ? "text-center" : ""
                    }`}
                >
                    {isCollapsed ? <FaBars size={16} /> : <FaTimes size={16} />}
                </button>

                {/* Branch Selection */}
                <div
                    onClick={() => setBranchModal(true)}
                    className={`mx-5 p-3 rounded-lg cursor-pointer text-sm font-semibold text-white hover:bg-[#2a3655] transition-all duration-300 ${
                        isCollapsed ? "text-center" : ""
                    }`}
                >
                    {JSON.parse(localStorage.getItem("userInfo"))?.branchID == null && (
                        <div className="relative group">
                            <span className="truncate overflow-hidden whitespace-nowrap block max-w-[180px]">{isCollapsed ? <img src="/office-svgrepo-com.svg" alt="Branch Icon" className="h-6 w-6 mr-3" /> : currentBranch}</span>
                            {isCollapsed && (
                                <div className="absolute left-16 hidden group-hover:block bg-[#151e46] text-white p-2 rounded-md shadow-lg">
                                    {currentBranch}
                                </div>
                            )}
                        </div>
                    )}
                </div>

                {/* Navigation Links */}
                <div className="flex-1 flex flex-col px-4 py-5 space-y-2">
                    {[
                        { to: "/dashboard", text: props.text6, icon: props.imageSrc1, alt: props.imageAlt6 },
                        { to: "/menu", text: props.text1, icon: props.imageSrc3, alt: props.imageAlt1 },
                        { to: "/orders", text: props.text2, icon: props.imageSrc4, alt: props.imageAlt2 },
                        { to: "/tables", text: props.text3, icon: props.imageSrc11, alt: props.imageAlt3 },
                        { to: "/reviews", text: props.text4, icon: props.imageSrc10, alt: props.imageAlt4 },
                        { to: "/transactions", text: props.text8, icon: props.imageSrc9, alt: props.imageAlt9 },
                    ].map((item, index) => (
                        <Link
                            key={index}
                            to={item.to}
                            className={`group relative ${linkClass(item.to.slice(1))}`}
                            onClick={() => handleLinkClick(item.to.slice(1))}
                        >
                            <img alt={item.alt} src={item.icon} className="h-6 w-6 mr-3" />
                            {!isCollapsed ? item.text : (
                                <span className="absolute left-14 top-1/2 transform -translate-y-1/2 bg-[#151e46] text-white text-sm px-2 py-1 rounded-md shadow-md opacity-0 pointer-events-none group-hover:opacity-100 transition-opacity duration-300">
                                    {item.text}
                                </span>
                            )}
                        </Link>
                    ))}
                </div>

                {/* Feedback Section */}
                <div className="w-full px-4 py-6">
                    <div
                        className={`w-full flex flex-col items-center justify-center rounded-xl bg-[#40b06d] p-4 transition-all duration-300 ${
                            isCollapsed ? "px-2" : "px-4"
                        }`}
                    >
            <span className={`text-[#fff7e1] text-sm text-center mb-2 ${isCollapsed ? "hidden" : ""}`}>
              {props.text7}
            </span>
                        <button
                            onClick={() => setFeedbackModal(true)}
                            className={`text-[#40b06d] text-sm font-semibold bg-[#fff7e1] rounded-lg hover:bg-[#e0e0e0] transition-all duration-300 ${
                                isCollapsed ? "p-2" : "px-4 py-2"
                            }`}
                        >
                            {isCollapsed ? "⭐" : props.button}
                        </button>
                    </div>
                </div>

                {/* Logout Section */}
                <div className="w-full flex items-center justify-center py-6">
                    <button
                        className={linkClass("logout")}
                        onClick={handleLogout}
                    >
                        <img alt={props.imageAlt8} src={props.imageSrc8} className="h-6 w-6 mr-3" />
                        {!isCollapsed && props.text5}
                    </button>
                </div>
            </div>

            {/* Modals */}
            {feedbackModal && <FeedbackModal setShowCallout={setShowCallout} onClose={setFeedbackModal} />}
            {branchModal && <SelectBranchModal canClose={true} onClose={onBranchModalClose} />}
            {showCallout && (error || message) && (
                <BottomCallout
                    setShowCallout={setShowCallout}
                    message={error || message}
                    variant={error ? "error" : "success"}
                />
            )}
        </div>
    );
};

Sidebar.defaultProps = {
    text: "SERV",
    text8: "Transactions",
    text4: "Reviews",
    text2: "Orders",
    text5: "Logout",
    text3: "Tables",
    imageSrc: "/logout-2-svgrepo-com.svg",
    imageAlt: "image",
    button: "Rate Experience",
    text7: "Help us improve our services",
    text6: "Dashboard",
    text1: "Menu",
    imageSrc6: "/menu-alt-2-svgrepo-com%20(2).svg",
    imageSrc1: "/home-20-svgrepo-com.svg",
    imageSrc2: "/menu-wines-wine-svgrepo-com.svg",
    imageSrc3: "/food-dinner-dish-plate-fork-svgrepo-com.svg",
    imageSrc4: "/menu-board-svgrepo-com.svg",
    imageSrc8: "/logout-2-svgrepo-com.svg",
    imageSrc9: "/cash-svgrepo-com.svg",
    imageSrc10: "/start-favorite-svgrepo-com.svg",
    imageSrc11: "/table-svgrepo-com.svg",
};

Sidebar.propTypes = {
    text: PropTypes.string,
    text8: PropTypes.string,
    text4: PropTypes.string,
    text2: PropTypes.string,
    text5: PropTypes.string,
    text3: PropTypes.string,
    imageSrc: PropTypes.string,
    imageAlt: PropTypes.string,
    button: PropTypes.string,
    text7: PropTypes.string,
    text6: PropTypes.string,
    text1: PropTypes.string,
    imageSrc6: PropTypes.string,
    imageSrc1: PropTypes.string,
    imageSrc2: PropTypes.string,
    imageSrc3: PropTypes.string,
    imageSrc4: PropTypes.string,
    imageSrc8: PropTypes.string,
    imageSrc9: PropTypes.string,
};

export default Sidebar;