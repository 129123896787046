import {
    LIST_TRANSACTIONS_SUCCESS, 
    LIST_TRANSACTIONS_REQUEST, 
    LIST_TRANSACTIONS_FAILURE 
} from "./constants";
import baseURL from "../baseURL";
import axios from "axios";

export const listTransactionsAction = (queryStr) => async (dispatch) => {
    try {
        dispatch({
            type: LIST_TRANSACTIONS_REQUEST,
        });
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true
        };

        if (localStorage.getItem('branchID') !== "0")
            queryStr += `&branch_id=${localStorage.getItem('branchID')}`

        else{
            const userInfo = localStorage.getItem("userInfo");
            const userObject = JSON.parse(userInfo);
            const userId = userObject?.id;
            queryStr += `&establishment_id=${userId}`
        }
            

        const {data} = await axios.get(`${baseURL}/transactions?${queryStr}`, config);

        dispatch({
            type: LIST_TRANSACTIONS_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: LIST_TRANSACTIONS_FAILURE,
            payload: error.response
                ? error.response.data.message
                : error.message,
        });
    }
};