import {
    LIST_TRANSACTIONS_SUCCESS, 
    LIST_TRANSACTIONS_REQUEST, 
    LIST_TRANSACTIONS_FAILURE 
} from "./constants";
import { DEFAULT_ROWS_PER_PAGE } from "../consts";

export const listTransactionsReducer = (state = {}, action) => {
    switch (action.type) {
        case LIST_TRANSACTIONS_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case LIST_TRANSACTIONS_SUCCESS:
            return {
                ...state,
                transactions: action.payload.data,
                countPages: Math.ceil(action.payload.listLength / DEFAULT_ROWS_PER_PAGE),
                countRows: action.payload.listLength,
                loading: false,
                error: null,
            };
        case LIST_TRANSACTIONS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};