import React from 'react'
import ReactDOM from 'react-dom'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'

import './style.css'
import Menu from './views/menu/menu'
import Login from './views/auth/login'
import ResetPass from './views/auth/reset-pass'
import Orders from './views/order/orders'
import Dashboard from './views/dashboard/dashboard'
import Tables from './views/order/tables'
import ForgotPassEmail from './views/auth/forgot-pass-email'
import Reviews from './views/review/reviews'
import ForgotPassOTP from './views/auth/forgot-pass-otp'
import OrderSingleResponsive from './views/order/order-single-responsive'
import MenuItemResponsive from './views/menu/menu-item-responsive'
import NotFound from './views/misc/not-found'
import Transactions from './views/transactions/transactions'
import { ReduxProvider } from './redux/provider'
import AuthWrapper from "./hooks/auth/AuthWrapper";


const App = () => {
  return (
      <ReduxProvider>
    <Router>
      <AuthWrapper />
      <Switch>

        {/*Auth*/}
        <Route component={Login} exact path="/" />
        <Route component={ResetPass} exact path="/reset-pass" />
        <Route component={ForgotPassEmail} exact path="/forgot-pass-email" />
        <Route component={ForgotPassOTP} exact path="/forgot-pass-otp" />

        {/*Orders*/}
        <Route component={Orders} exact path="/orders" />
        <Route component={Transactions} exact path="/transactions" />
        <Route component={Tables} exact path="/tables" />
        <Route component={Reviews} exact path="/reviews" />
        <Route
            component={OrderSingleResponsive}
            exact
            path="/orders/:id"
        />

        <Route component={Dashboard} exact path="/dashboard" />



        {/*Menu*/}
        <Route component={Menu} exact path="/menu" />
        <Route
          component={MenuItemResponsive}
          exact
          path="/menu-item-responsive"
        />

        <Route component={NotFound} path="**" />
        <Redirect to="**" />
      </Switch>
      </Router>
      </ReduxProvider>
  )
}

ReactDOM.render(<App />, document.getElementById('app'))
