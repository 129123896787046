import React from "react";
import LoadingSpinner from "./LoadingSpinner"; // Import your custom spinner

const CustomButton = ({ onClick, loading, children }) => {
    return (
        <button
            onClick={onClick}
            disabled={loading}
            className={`w-full py-3 bg-[#F0532F] text-white text-lg font-semibold rounded-lg hover:bg-[#F0532F]/90 transition-colors flex items-center justify-center ${
                loading ? "cursor-not-allowed opacity-80" : ""
            }`}
        >
            {loading ? <LoadingSpinner /> : children}
        </button>
    );
};

export default CustomButton;