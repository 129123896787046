import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Sidebar from '../../components/Sidebar';
import Table from "../../components/Table";
import FiltersModal from "../../components/FiltersModal";
import ReviewModal from './components/ReviewModal';
import Skeleton from "react-loading-skeleton";
import { motion } from 'framer-motion';
import { useDispatch, useSelector } from "react-redux";
import { listOrderFeedbacksAction } from "../../redux/feedback/actions";

const attributeNames = ['rating', 'orderID', 'customerFirstName'];
const attributeDisplayNames = ['Rating/5', 'Order ID', 'Customer', 'View'];
const filters = [
    { type: 'text', label: 'Order ID', queryString: 'order_id' },
    { type: 'text', label: 'Rating', queryString: 'rating' },
];

const Reviews = () => {
    const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
    const [isReviewModalOpen, setIsReviewModalOpen] = useState(false);
    const [selectedReview, setSelectedReview] = useState(null);
    const [page, setPage] = useState(1);
    const [queryString, setQueryString] = useState('');

    const dispatch = useDispatch();
    const listOrderFeedbacksReducer = useSelector((state) => state.listOrderFeedbacksReducer);

    // Fetching reviews
    useEffect(() => {
        dispatch(listOrderFeedbacksAction(`${queryString}&page=${page}&max=10`));
    }, [dispatch, page, queryString]);

    const handleFilterSubmit = (queryStr) => {
        setIsFilterModalOpen(false);
        setQueryString(queryStr);
        setPage(1);
        dispatch(listOrderFeedbacksAction(queryStr + `&page=${page}&max=10`));
    };

    const handleRowClick = (review) => {
        setSelectedReview(review);
        setIsReviewModalOpen(true);
    };

    return (
        <div className="flex min-h-screen flex-row justify-between bg-[#fff7e1]">
            <Helmet>
                <title>Reviews - Serv</title>
                <meta property="og:title" content="Orders - Serv Restaurant Interface"/>
            </Helmet>
            <Sidebar/>
            <div className="flex flex-col w-full ml-[100px]">
                <div className="flex justify-between items-center my-4">
                    <h1 className="text-5xl font-bold text-[#151e46]">Reviews</h1>
                    <div className="orders-container04" onClick={() => setIsFilterModalOpen(true)}>
                        <img
                            alt="Filter"
                            src="/filter-list-svgrepo-com%20(1).svg"
                            className="orders-image"
                        />
                        <span className="orders-text01">Filter</span>
                    </div>
                </div>

                <div className="flex justify-center">
                    {listOrderFeedbacksReducer.loading ? (
                        <div className="w-full space-y-4">
                            <Skeleton count={10} height={60} baseColor="#f5f5dc" highlightColor="#e1e1c9"/>
                        </div>
                    ) : (
                        <motion.div
                            initial={{opacity: 0}}
                            animate={{opacity: 1}}
                            exit={{opacity: 0}}
                            className="w-full"
                        >
                            {listOrderFeedbacksReducer.feedbacks && (
                                <Table
                                    data={listOrderFeedbacksReducer.feedbacks}
                                    cols={attributeNames}
                                    colNums={4}
                                    attributeDisplayNames={attributeDisplayNames}
                                    onRowClick={handleRowClick}
                                    page={page}
                                    setPage={setPage}
                                    maxPage={listOrderFeedbacksReducer.countPages}
                                    countRows={listOrderFeedbacksReducer.countRows}
                                />
                            )}
                        </motion.div>
                    )}
                </div>
            </div>

            {isFilterModalOpen && (
                <FiltersModal
                    onClose={()=> setIsFilterModalOpen(false)}
                    title="Filter Reviews"
                    filters={filters}
                    onSubmit={handleFilterSubmit}
                    buttonLabel="Apply Filters"
                />
            )}

            {isReviewModalOpen && selectedReview && (
                <ReviewModal
                    isOpen={isReviewModalOpen}
                    review={selectedReview}
                    onClose={() => setIsReviewModalOpen(false)}
                />
            )}
        </div>
    );
};

export default Reviews;
