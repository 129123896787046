import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { listMenuAction, updateItemAvailability } from "../../redux/menu/actions";
import Sidebar from "../../components/Sidebar";
import BottomCallout from "../../components/BottomCallout";
import { Link } from "react-router-dom";
import NewItemModal from "./components/NewItemModal";
import { motion, AnimatePresence } from "framer-motion";
import { FaPlus, FaEdit, FaToggleOn, FaToggleOff } from "react-icons/fa";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const Menu = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState("all");
    const [categoryNames, setCategoryNames] = useState([]);
    const dispatch = useDispatch();

    const menuList = useSelector((state) => state.listMenuReducer);
    const itemAvailabilityUpdateReducer = useSelector(
        (state) => state.itemAvailabilityUpdateReducer
    );
    const { menu, loading, error } = menuList;

    const filteredMenu =
        selectedCategory === "all"
            ? menu
            : menu?.filter((category) => category.categoryName === selectedCategory);

    useEffect(() => {
        dispatch(listMenuAction());
    }, [dispatch, itemAvailabilityUpdateReducer]);

    useEffect(() => {
        if (menu) {
            setCategoryNames(menu.map((category) => ({ id: category.id, name: category.categoryName })));
        }
    }, [menu]);

    const handleCategoryChange = (category) => {
        setSelectedCategory(category);
    };

    return (
        <div className="flex min-h-screen flex-row justify-between bg-[#fff7e1]">
            <Helmet>
                <title>Menu - Serv Restaurant Interface</title>
                <meta property="og:title" content="Menu - Serv Restaurant Interface" />
            </Helmet>
            <Sidebar />
            <div className="ml-20 flex flex-col items-center justify-start bg-[#fff7e1] w-full overflow-hidden px-6 py-12 transition-all duration-300">
                {/* Header Section */}
                <div className="flex justify-between items-start w-full mb-8">
                    <motion.h1
                        initial={{ opacity: 0, y: -20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                        className="text-[#151e46] text-5xl font-bold"
                    >
                        Menu
                    </motion.h1>
                    <motion.button
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        onClick={() => setIsModalOpen(true)}
                        className="flex items-center justify-center bg-[#151e46] rounded-2xl px-6 py-3 hover:bg-[#2a3655] transition-all duration-200"
                    >
                        <FaPlus className="text-[#fff7e1] text-xl mr-2" />
                        <span className="text-[#fff7e1] text-xl">New Item</span>
                    </motion.button>
                </div>

                {/* Category Filters */}
                <div className="w-full flex mb-8">
                    <div className="flex overflow-x-auto p-4 space-x-4 w-full">
                        <motion.button
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                            onClick={() => handleCategoryChange("all")}
                            className={`py-2 px-4 rounded-2xl transition-all duration-200 ${
                                selectedCategory === "all"
                                    ? "bg-[#151e46] text-white"
                                    : "bg-white text-[#151e46] hover:bg-[#f0f0f0]"
                            }`}
                        >
                            All
                        </motion.button>
                        {categoryNames.map((category) => (
                            <motion.button
                                key={category.id}
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                                onClick={() => handleCategoryChange(category.name)}
                                className={`py-2 px-4 rounded-2xl transition-all duration-200 ${
                                    selectedCategory === category.name
                                        ? "bg-[#151e46] text-white"
                                        : "bg-white text-[#151e46] hover:bg-[#f0f0f0]"
                                }`}
                            >
                                {category.name}
                            </motion.button>
                        ))}
                    </div>
                </div>

                {/* Error Handling */}
                {error && (
                    <BottomCallout
                        message={error}
                        variant="error"
                    />
                )}

                {/* Loading State */}
                {loading && categoryNames?.length === 0 && (
                    <div className="w-full">
                        {[...Array(3)].map((_, index) => (
                            <div key={index} className="w-full mb-8">
                                <Skeleton height={40} width={200} className="mb-6" />
                                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 w-full">
                                    {[...Array(6)].map((_, i) => (
                                        <div key={i} className="bg-[#d7f1df] rounded-lg p-4">
                                            <Skeleton height={100} className="mb-4" />
                                            <Skeleton height={20} width={150} className="mb-2" />
                                            <Skeleton height={20} width={100} />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                )}

                {/* Menu Items */}
                <AnimatePresence>
                    {filteredMenu &&
                        filteredMenu.map((category) => (
                            <motion.div
                                key={category.id}
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                exit={{ opacity: 0, y: -20 }}
                                transition={{ duration: 0.5 }}
                                className="w-full mb-8"
                            >
                                <div className="flex justify-between items-center w-full mb-6">
                  <span className="text-[#151e46] text-4xl font-medium">
                    {category.categoryName}
                  </span>
                                </div>
                                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 w-full">
                                    {category.menuItems?.map((item) => (
                                        <motion.div
                                            key={item.id}
                                            whileHover={{ scale: 1.02 }}
                                            className="bg-[#d7f1df] rounded-lg p-4 shadow-md"
                                        >
                                            <Link
                                                to={{
                                                    pathname: `/menu-item-responsive`,
                                                    state: { item, categoryName: category.categoryName },
                                                }}
                                            >
                                                <div className="flex flex-col sm:flex-row items-start sm:items-center mb-4">
                                                    <img
                                                        alt="image"
                                                        src={item.image_url}
                                                        className="w-24 h-24 object-cover mb-4 sm:mb-0 sm:mr-4 rounded-lg"
                                                    />
                                                    <div className="flex flex-col w-full">
                                                        <div className="flex justify-between items-center mb-2">
                              <span className="text-[#151e46] text-xl font-semibold">
                                {item.name}
                              </span>
                                                            <span className="text-[#151e46] text-lg font-medium">
                                {item.price} EGP
                              </span>
                                                        </div>
                                                        <span className="text-[#151e46] text-base">
                              {item.description}
                            </span>
                                                    </div>
                                                </div>
                                            </Link>
                                            <div className="flex justify-between items-center w-full">
                                                <div className="flex items-center">
                                                    {localStorage.getItem("branchID") !== "0" && (
                                                        <>
                                                            <motion.button
                                                                whileHover={{ scale: 1.1 }}
                                                                whileTap={{ scale: 0.9 }}
                                                                onClick={() =>
                                                                    dispatch(
                                                                        updateItemAvailability(
                                                                            localStorage.getItem("branchID"),
                                                                            item.id,
                                                                            !item.is_available_toggle
                                                                        )
                                                                    )
                                                                }
                                                                className="mr-2"
                                                            >
                                                                {item.is_available_toggle ? (
                                                                    <FaToggleOn className="text-[#40b06d] text-2xl" />
                                                                ) : (
                                                                    <FaToggleOff className="text-[#151e46] text-2xl" />
                                                                )}
                                                            </motion.button>
                                                            <span className="text-[#151e46] text-sm">
                                {item.is_available_toggle ? "Available" : "Unavailable"}
                              </span>
                                                        </>
                                                    )}
                                                </div>
                                                <Link
                                                    to={{
                                                        pathname: `/menu-item-responsive`,
                                                        state: { item, categoryName: category.categoryName },
                                                    }}
                                                >
                                                    <motion.button
                                                        whileHover={{ scale: 1.1 }}
                                                        whileTap={{ scale: 0.9 }}
                                                    >
                                                        <FaEdit className="text-[#151e46] text-2xl cursor-pointer" />
                                                    </motion.button>
                                                </Link>
                                            </div>
                                        </motion.div>
                                    ))}
                                </div>
                                <br />
                                <hr className="border-[#151e46]/10" />
                            </motion.div>
                        ))}
                </AnimatePresence>

                {/* New Item Modal */}
                {isModalOpen && (
                    <NewItemModal
                        isOpen={isModalOpen}
                        onClose={() => setIsModalOpen(false)}
                        categoryNames={categoryNames}
                    />
                )}
            </div>
        </div>
    );
};

export default Menu;