import React from "react";
import { motion } from "framer-motion";

const LoadingSpinner = () => {
    return (
        <motion.div
            className="flex items-center justify-center"
            animate={{ rotate: 360 }}
            transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
        >
            <div className="w-6 h-6 border-4 border-[#FFF7E1] border-t-[#F0532F] rounded-full"></div>
        </motion.div>
    );
};

export default LoadingSpinner;