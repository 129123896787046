import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import {
  FaStore,
  FaMoneyBillWave,
  FaPhone,
  FaEnvelope,
  FaCalendarAlt,
  FaChair,
  FaLock,
  FaUtensils,
  FaTag,
  FaCheckCircle,
  FaTable,
  FaBuilding,
  FaInfoCircle,
} from "react-icons/fa";
import { IoFastFood } from "react-icons/io5";
import { VscGraph } from "react-icons/vsc";
import {
  getEstablishmentAction,
  getEstablishmentBranchAction,
  getMiscAnalyticsAction,
  updateEstablishmentAction,
} from "../../redux/establishment/actions";
import Sidebar from "../../components/Sidebar";
import Skeleton from "react-loading-skeleton";
import { motion } from "framer-motion";
import "react-loading-skeleton/dist/skeleton.css";

const ToggleSwitch = ({ isChecked, onChange }) => (
    <label className="relative inline-flex items-center cursor-pointer">
      <input
          type="checkbox"
          checked={isChecked}
          onChange={onChange}
          className="sr-only peer"
      />
      <div className="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-[#FFF7E1] after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-600"></div>
    </label>
);

const Dashboard = () => {
  const dispatch = useDispatch();

  const updateEstablishmentReducer = useSelector(
      (state) => state?.updateEstablishmentReducer
  );
  const establishment = useSelector(
      (state) => state?.getEstablishmentReducer?.establishment
  );
  const establishmentBranch = useSelector(
      (state) => state?.getEstablishmentBranchReducer?.establishmentBranch
  );
  const miscAnalytics = useSelector(
      (state) => state?.getMiscAnalyticsReducer?.analytics
  );

  const [allowsOnlinePayments, setAllowsOnlinePayments] = useState(
      establishmentBranch?.allowsOnlinePayments
  );
  const [allowsCashPayments, setAllowsCashPayments] = useState(
      establishmentBranch?.allowsCashPayments
  );
  const [allowsPickup, setAllowsPickup] = useState(
      establishmentBranch?.allowsPickup
  );
  const [allowsDineIn, setAllowsDineIn] = useState(
      establishmentBranch?.allowsDineIn
  );
  const [acceptingOrders, setAcceptingOrders] = useState(
      establishmentBranch?.acceptingOrders
  );
  const [opensAt, setOpensAt] = useState(establishmentBranch?.opensAt || "");
  const [closesAt, setClosesAt] = useState(establishmentBranch?.closesAt || "");

  const isInitialized = useRef(false);

  useEffect(() => {
    if (establishmentBranch && !isInitialized.current) {
      setAllowsOnlinePayments(establishmentBranch.allowsOnlinePayments);
      setAllowsCashPayments(establishmentBranch.allowsCashPayments);
      setAllowsPickup(establishmentBranch.allowsPickup);
      setAllowsDineIn(establishmentBranch.allowsDineIn);
      setAcceptingOrders(establishmentBranch.acceptingOrders);
      setClosesAt(establishmentBranch.closesAt);
      setOpensAt(establishmentBranch.opensAt);
      isInitialized.current = true; // Mark as initialized
    }
  }, [establishmentBranch]);

  useEffect(() => {
    if (updateEstablishmentReducer.loading !== true) {
      dispatch(getEstablishmentAction());
      dispatch(getEstablishmentBranchAction());
      dispatch(
          getMiscAnalyticsAction({
            branchId: parseInt(localStorage?.getItem("branchID")),
          })
      );
    }
  }, [dispatch, updateEstablishmentReducer.loading]);

  const handleBranchTimingChange = (field, value) => {
    if (field === "opensAt") {
      setOpensAt(value);
      dispatch(updateEstablishmentAction({ opensAt: value }, true));
    } else {
      dispatch(updateEstablishmentAction({ closesAt: value }, true));
      setClosesAt(value);
    }
  };

  const handleToggleChange = (key, value) => {
    switch (key) {
      case "allowsOnlinePayments":
        setAllowsOnlinePayments(value);
        break;
      case "allowsCashPayments":
        setAllowsCashPayments(value);
        break;
      case "allowsPickup":
        setAllowsPickup(value);
        break;
      case "allowsDineIn":
        setAllowsDineIn(value);
        break;
      case "acceptingOrders":
        setAcceptingOrders(value);
        break;
      default:
        break;
    }
    const settings = {
      allowsOnlinePayments:
          key === "allowsOnlinePayments" ? value : allowsOnlinePayments,
      allowsCashPayments:
          key === "allowsCashPayments" ? value : allowsCashPayments,
      allowsPickup: key === "allowsPickup" ? value : allowsPickup,
      allowsDineIn: key === "allowsDineIn" ? value : allowsDineIn,
      acceptingOrders: key === "acceptingOrders" ? value : acceptingOrders,
    };
    const updatedBranchSettings = { ...establishmentBranch, ...settings };
    dispatch(updateEstablishmentAction(updatedBranchSettings, true));
  };

  return (
      <>
        <Helmet>
          <title>Dashboard - Serv Restaurant Interface</title>
          <meta
              property="og:title"
              content="Dashboard - Serv Restaurant Interface"
          />
        </Helmet>
        <Sidebar />
        <div className="flex h-screen font-sans text-lg">
          <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.3 }}
              className="flex-1 bg-[#FFF7E1] ml-20 p-8 overflow-auto"
          >
            <div className="space-y-8">
              {/* Header Section */}
              <div className="flex items-center mb-8">
                <motion.div
                    className="w-16 h-16 bg-blue-900 rounded-full shadow-lg"
                    whileHover={{ scale: 1.05 }}
                >
                  {establishment?.logoUrl ? (
                      <motion.img
                          src={establishment.logoUrl}
                          alt="Logo"
                          className="w-full h-full object-cover rounded-full"
                          initial={{ scale: 0 }}
                          animate={{ scale: 1 }}
                          transition={{ duration: 0.2 }}
                      />
                  ) : (
                      <Skeleton circle height={64} width={64} />
                  )}
                </motion.div>
                <div className="ml-4">
                  <h2 className="text-4xl text-[#151e46] font-bold">
                    {establishment?.establishmentName || <Skeleton width={200} />}
                  </h2>

                </div>
              </div>

              {/* Restaurant Information */}
              <motion.div
                  className="bg-[#FFF7E1] p-6 rounded-xl shadow-lg"
                  initial={{ y: 10, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ duration: 0.3 }}
              >
                <h3 className="text-3xl font-bold text-[#151e46] flex items-center space-x-4 mb-6">
                  <FaStore className="text-[#40b06d] text-3xl" />
                  <span>Restaurant Information</span>
                </h3>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div className="flex items-center space-x-4">
                    <FaUtensils className="text-[#40b06d] text-2xl" />
                    <div>
                      <p className="text-[#151e46] font-bold text-lg">Restaurant Name</p>
                      <p className="text-[#151e46] text-lg">
                        {establishment?.establishmentName || <Skeleton />}
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center space-x-4">
                    <FaEnvelope className="text-[#40b06d] text-2xl" />
                    <div>
                      <p className="text-[#151e46] font-bold text-lg">Email</p>
                      <p className="text-[#151e46] text-lg">
                        {JSON?.parse(localStorage?.getItem("userInfo"))?.username || <Skeleton />}
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center space-x-4">
                    <FaPhone className="text-[#40b06d] text-2xl" />
                    <div>
                      <p className="text-[#151e46] font-bold text-lg">Phone</p>
                      <p className="text-[#151e46] text-lg">
                        {JSON?.parse(localStorage?.getItem("userInfo"))?.phoneNumber || <Skeleton />}
                      </p>
                    </div>
                  </div>
                  <div className="col-span-2 flex items-center space-x-4">
                    <FaInfoCircle className="text-[#40b06d] text-2xl" />
                    <div>
                      <p className="text-[#151e46] font-bold text-lg">About</p>
                      <p className="text-[#151e46] text-lg">
                        {establishment?.description || <Skeleton count={2} />}
                      </p>
                    </div>
                  </div>
                </div>
              </motion.div>

              {/* Branch Information */}
              {localStorage?.getItem("branchID") !== "0" && (
                  <motion.div
                      className="bg-[#FFF7E1] p-6 rounded-xl shadow-lg"
                      initial={{ y: 10, opacity: 0 }}
                      animate={{ y: 0, opacity: 1 }}
                      transition={{ duration: 0.3, delay: 0.1 }}
                  >
                    <h3 className="text-3xl font-bold text-[#151e46] flex items-center space-x-4 mb-6">
                      <FaBuilding className="text-[#40b06d] text-3xl" />
                      <span>Branch Information</span>
                    </h3>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                      <div className="flex items-center space-x-4">
                        <FaTable className="text-[#40b06d] text-2xl" />
                        <div>
                          <p className="text-[#151e46] font-bold text-lg">Number of Tables</p>
                          <p className="text-[#151e46] text-lg">
                            {establishmentBranch?.numTables || <Skeleton />}
                          </p>
                        </div>
                      </div>
                      <div className="flex items-center space-x-4">
                        <FaCheckCircle className="text-[#40b06d] text-2xl" />
                        <div>
                          <p className="text-[#151e46] font-bold text-lg">Active</p>
                          <p className="text-[#151e46] text-lg">
                            {establishmentBranch?.active ? "Yes" : "No" || <Skeleton />}
                          </p>
                        </div>
                      </div>
                      <div className="flex items-center space-x-4">
                        <FaCalendarAlt className="text-[#40b06d] text-2xl" />
                        <div>
                          <p className="text-[#151e46] font-bold text-lg">Branch Created On</p>
                          <p className="text-[#151e46] text-lg">
                            {establishmentBranch?.branchCreatedAt ? new Date(establishmentBranch.branchCreatedAt).toLocaleDateString() : <Skeleton />}
                          </p>
                        </div>
                      </div>
                      <div className="flex items-center space-x-4">
                        <FaTag className="text-[#40b06d] text-2xl" />
                        <div>
                          <p className="text-[#151e46] font-bold text-lg">Branch Display As</p>
                          <p className="text-[#151e46] text-lg">
                            {establishmentBranch?.addressDisplayAs || <Skeleton />}
                          </p>
                        </div>
                      </div>
                      <div className="flex items-center space-x-4">
                        <FaCalendarAlt className="text-[#40b06d] text-2xl" />
                        <div>
                          <p className="text-[#151e46] font-bold text-lg">Starting Time</p>
                          <input
                              type="time"
                              value={opensAt}
                              onChange={(e) => handleBranchTimingChange("opensAt", e.target.value)}
                              className="border border-[#40b06d] bg-transparent rounded-lg px-3 py-2 text-[#151e46] focus:outline-none focus:ring-2 focus:ring-[#40b06d] hover:shadow-md transition-all duration-200 ease-in-out"
                          />
                        </div>
                      </div>
                      <div className="flex items-center space-x-4">
                        <FaCalendarAlt className="text-[#40b06d] text-2xl" />
                        <div>
                          <p className="text-[#151e46] font-bold text-lg">Closing Time</p>
                          <input
                              type="time"
                              value={closesAt}
                              onChange={(e) => handleBranchTimingChange("closesAt", e.target.value)}
                              className="border border-[#40b06d] bg-transparent rounded-lg px-3 py-2 text-[#151e46] focus:outline-none focus:ring-2 focus:ring-[#40b06d] hover:shadow-md transition-all duration-200 ease-in-out"
                          />
                        </div>
                      </div>
                    </div>
                  </motion.div>
              )}

              {/* Branch Settings */}
              {localStorage?.getItem("branchID") !== "0" && (
                  <motion.div
                      className="bg-[#FFF7E1] p-6 rounded-xl shadow-lg"
                      initial={{ y: 10, opacity: 0 }}
                      animate={{ y: 0, opacity: 1 }}
                      transition={{ duration: 0.3, delay: 0.2 }}
                  >
                    <h3 className="text-3xl font-bold text-[#151e46] flex items-center space-x-4 mb-6">
                      <FaLock className="text-[#40b06d] text-3xl" />
                      <span>Branch Settings</span>
                    </h3>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                      <div className="flex items-center space-x-4">
                        <FaMoneyBillWave className="text-[#40b06d] text-2xl" />
                        <div>
                          <p className="text-[#151e46] font-bold text-lg">Online Payment</p>
                          <ToggleSwitch
                              isChecked={allowsOnlinePayments}
                              onChange={() =>
                                  handleToggleChange("allowsOnlinePayments", !allowsOnlinePayments)
                              }
                          />
                        </div>
                      </div>
                      <div className="flex items-center space-x-4">
                        <FaMoneyBillWave className="text-[#40b06d] text-2xl" />
                        <div>
                          <p className="text-[#151e46] font-bold text-lg">Cash Payment</p>
                          <ToggleSwitch
                              isChecked={allowsCashPayments}
                              onChange={() =>
                                  handleToggleChange("allowsCashPayments", !allowsCashPayments)
                              }
                          />
                        </div>
                      </div>
                      <div className="flex items-center space-x-4">
                        <IoFastFood className="text-[#40b06d] text-2xl" />
                        <div>
                          <p className="text-[#151e46] font-bold text-lg">Pickup Orders</p>
                          <ToggleSwitch
                              isChecked={allowsPickup}
                              onChange={() => handleToggleChange("allowsPickup", !allowsPickup)}
                          />
                        </div>
                      </div>
                      <div className="flex items-center space-x-4">
                        <FaChair className="text-[#40b06d] text-2xl" />
                        <div>
                          <p className="text-[#151e46] font-bold text-lg">Dine In Orders</p>
                          <ToggleSwitch
                              isChecked={allowsDineIn}
                              onChange={() => handleToggleChange("allowsDineIn", !allowsDineIn)}
                          />
                        </div>
                      </div>
                      <div className="flex items-center space-x-4">
                        <IoFastFood className="text-[#40b06d] text-2xl" />
                        <div>
                          <p className="text-[#151e46] font-bold text-lg">Accepting Orders</p>
                          <ToggleSwitch
                              isChecked={acceptingOrders}
                              onChange={() =>
                                  handleToggleChange("acceptingOrders", !acceptingOrders)
                              }
                          />
                        </div>
                      </div>
                    </div>
                  </motion.div>
              )}

              {/* Analytics */}
              <motion.div
                  className="bg-[#FFF7E1] p-6 rounded-xl shadow-lg"
                  initial={{ y: 10, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ duration: 0.3, delay: 0.3 }}
              >
                <h3 className="text-3xl font-bold text-[#151e46] flex items-center space-x-4 mb-6">
                  <VscGraph className="text-[#40b06d] text-3xl" />
                  <span>Analytics</span>
                </h3>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                  {[
                    { label: "Total Revenue", value: `EGP ${miscAnalytics?.totalGenerated}` },
                    { label: "All Transactions Count", value: miscAnalytics?.countTransactions },
                    { label: "Online Payment Revenue", value: `EGP ${miscAnalytics?.totalPaidGross}` },
                    { label: "Online Payment After Fees", value: `EGP ${miscAnalytics?.totalPaidNet}` },
                    { label: "Refunds Count", value: miscAnalytics?.countRefundTransactions },
                    { label: "Total Refunded", value: `EGP ${miscAnalytics?.totalRefundTransactions}` },
                  ].map((item, index) => (
                      <div
                          key={index}
                          className="bg-[#FFF7E1] p-4 rounded-lg flex items-center space-x-4"
                      >
                        <FaMoneyBillWave className="text-[#40b06d] text-2xl" />
                        <div>
                          <p className="text-[#151e46] font-bold text-lg">{item.label}</p>
                          <p className="text-[#151e46] text-lg">{item.value || <Skeleton />}</p>
                        </div>
                      </div>
                  ))}
                </div>
              </motion.div>
            </div>
          </motion.div>
        </div>
      </>
  );
};

export default Dashboard;