import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import {useHistory, useLocation} from 'react-router-dom';
import Sidebar from '../../components/Sidebar';
import CustomizationOptions from "./components/CustomizationOptions";
import {listMenuAction, updateItemAvailability, updateMenuAction} from '../../redux/menu/actions';
import BottomCallout from '../../components/BottomCallout';
import './components/MenuItemEditView.css';
import {BsPencil} from "react-icons/bs";
import {FaTrash} from "react-icons/fa";
import { motion, AnimatePresence } from "framer-motion";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const MenuItemEditView = (props) => {
  const location = useLocation();
  const history = useHistory();

  const [item, setItem] = useState(location?.state?.item);
  const [categoryName, setCategoryName] = useState(location.state.categoryName);
  useEffect(() => {
    if (location.state) {
      setItem(location.state.item);
      setCategoryName(location.state.categoryName);
    }
  }, [location.state]);


  const [name, setName] = useState(item.name);
  const [price, setPrice] = useState(item.price);
  const [quantity, setQuantity] = useState(item.quantity);
  const [description, setDescription] = useState(item.description);
  const [availability, setAvailability] = useState(item.is_available_toggle);
  const [showCallout, setShowCallout] = useState(false);

  const [deleted, setDeleted] = useState(item.deleted_at != null);
  const [imageFile, setImageFile] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [opensAt, setOpensAt] = useState(item.opens_at);
  const [closesAt, setClosesAt] = useState(item.closes_at);
  const [removedID, setRemovedID] = useState(item.closes_at);
  const [customizationOption, setCustomizationOption] = useState({
    name: undefined,
    menuItemId: item.id,
    isRequired: false,
    choices: [],
    maxNumberOfChoices: 0,
  });
  const [customizationID, setCustomizationID] = useState(undefined);
  const [recommendedItems, setRecommendedItems] = useState([]);



  const dispatch = useDispatch();
  const menuUpdate = useSelector(state => state.menuUpdateReducer);
  const itemAvailabilityUpdateReducer = useSelector(state => state.itemAvailabilityUpdateReducer);
  const menuList = useSelector((state) => state.listMenuReducer.menu);
  const { loading, error } = menuUpdate;

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageFile(file);

      const reader = new FileReader();
      reader.onloadend = () => {
        setImageFile(file);
        setImageUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleDeleteOrRecycle = () => {
    const updateParams = {
      name: categoryName,
      branchID: parseInt(localStorage.getItem("branchID")),
      item: {
        delete: !deleted,
        recycle: deleted
      },
      itemID: item.id,
    };

    setDeleted(!deleted);
    dispatch(updateMenuAction(item.category_id, updateParams,imageFile));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!name || !price || !description) {
      alert("All fields are required.");
      return;
    }
    const updateParams = {
      name: categoryName,
      branchID: parseInt(localStorage.getItem("branchID")),
      item: {
        name,
        imageURL: item.imageURL,
        description,
        price,
        opensAt,
        closesAt,
      },
      itemID: item.id,
    };

    dispatch(updateMenuAction(item.category_id, updateParams,imageFile));
    dispatch(updateItemAvailability(localStorage.getItem("branchID"), item.id, undefined,quantity));
  };
  const handleRecommendationsSubmit = (e, id, removedID) => {
    e.preventDefault();
    setRemovedID(removedID);
    const updateParams = {
      name: categoryName,
      branchID: parseInt(localStorage.getItem("branchID")),
      item: {
        recommendedMenuItemID: id,
        removedRecommendedMenuItemID: removedID
      },
      itemID: item.id,
    };

    dispatch(updateMenuAction(item.category_id, updateParams, undefined));
  };

  useEffect(() => {
    if (menuUpdate.success && removedID) {
      const updatedRecommendedIds = item?.recommended_ids?.filter((recId) => recId !== removedID);
      setItem({ ...item, recommended_ids: updatedRecommendedIds });
      setRemovedID(null);
    }
  }, [menuUpdate.success, removedID]);

  useEffect(() => {
    if (menuList == null) {
      dispatch(listMenuAction())
    }
  }, [menuUpdate.success, removedID]);

  useEffect(() => {
    if (item?.recommended_ids && menuList) {
      // Flatten the menuItems from all categories
      const allMenuItems = menuList.flatMap(category => category.menuItems);

      // Find recommended items by matching IDs
      const recommended = item.recommended_ids
          .map(id => allMenuItems.find(menuItem => menuItem.id === id))
          .filter(Boolean); // Filter out undefined values

      setRecommendedItems(recommended);
    }
  }, [item, menuList]);

  useEffect(() => {


    if (menuUpdate?.menu?.data && menuUpdate.success) {
      const menu = menuUpdate.menu.data
      console.log(menu)
      const category = menu?.find((i) => Number(item?.category_id) === Number(i?.id));
      if(!category) return
      const updatedItem = category.menuItems?.find((i) => item.id == i.id);
      if (updatedItem) {
        history.replace({
          ...location,
          state: {
            ...location.state,
            item: updatedItem
          }
        });
      }
      setShowCallout(true);
      setTimeout(() => {
        setShowCallout(false);
      }, 2000);
    }
  }, [menuUpdate]);

  useEffect(() => {
    if (itemAvailabilityUpdateReducer && itemAvailabilityUpdateReducer.success) {
      history.replace({
        ...location,
        state: {
          ...location.state,
          item: {...item, is_available_toggle: itemAvailabilityUpdateReducer.item.is_available_toggle, quantity}
        }
      });
    }
    setShowCallout(true);
    setTimeout(() => {
      setShowCallout(false);
    }, 3000);
  }, [itemAvailabilityUpdateReducer,dispatch]);


  return (
      <div className="flex h-screen font-sans text-lg bg-[#fff7e1]">
        <Helmet>
          <title>Edit Item - Serv Restaurant Interface</title>
          <meta property="og:title" content="Edit Item - Serv Restaurant Interface"/>
        </Helmet>

        <Sidebar/>

        <div className="ml-20 flex-1 p-8 overflow-auto flex mb-4 justify-center">
          <motion.div
              initial={{opacity: 0, y: 20}}
              animate={{opacity: 1, y: 0}}
              transition={{duration: 0.5}}
              className="rounded-lg bg-[#fff7e1] p-8 w-full h-auto"
          >
            <div className="menu-item-container">
              {/* Left: Image */}
              <div className="menu-item-image lg:w-2/5">
                <div className="relative h-auto">
                  {item.image_url ? (
                      <img
                          alt="Item"
                          src={imageUrl ? imageUrl : item.image_url}
                          className="w-full h-[750px] object-cover rounded-lg shadow-lg"
                      />
                  ) : (
                      <Skeleton height={300}/>
                  )}
                  <div className="mt-2">
                    <button
                        onClick={() => {
                          document.getElementById("imageUpload").click();
                        }}
                        className="absolute top-2 right-2 hover:bg-[#151e46] text-white font-bold py-2 px-4 rounded"
                    >
                      <BsPencil className="text-lg"/>
                    </button>
                  </div>
                </div>
                {/* Hidden file input */}
                <input
                    type="file"
                    id="imageUpload"
                    accept="image/*"
                    className="hidden"
                    onChange={handleImageChange}
                />
              </div>

              {/* Right: Information */}
              <form onSubmit={handleSubmit} className="menu-item-info flex-1 lg:ml-8">
                <div className="flex items-center justify-between my-2">
                  <h2 className="text-4xl mb-4 mt-0 font-bold text-[#151e46]">{item.name ||
                      <Skeleton width={200}/>}</h2>
                  {deleted ? (
                      <button
                          className="bg-[#FF5722] text-white py-2 px-4 rounded-md"
                          onClick={handleDeleteOrRecycle}
                      >
                        Recycle Item
                      </button>
                  ) : (
                      <button
                          className="bg-[#FF5722] text-white py-2 px-4 rounded-md"
                          onClick={handleDeleteOrRecycle}
                      >
                        Delete Item
                      </button>
                  )}
                </div>

                <div className="mb-4">
                  <div className="mb-2">
                    <span className="text-[#151e46] font-bold">Category</span>
                  </div>
                  <div className="flex items-center mb-2">
                    <input
                        type="text"
                        className="p-2 bg-[#fff7e1] border-2 border-gray-300 rounded-md focus:outline-none focus:border-[#40b06d] w-full"
                        value={categoryName}
                        readOnly
                        required={true}
                    />
                  </div>

                  <div className="mb-2">
                    <span className="text-[#151e46] font-bold">Name</span>
                  </div>
                  <div className="flex items-center mb-2">
                    <input
                        type="text"
                        className="p-2 bg-[#fff7e1] border-2 border-gray-300 rounded-md focus:outline-none focus:border-[#40b06d] w-full"
                        value={name}
                        required={true}
                        onChange={(e) => setName(e.target.value)}
                    />
                  </div>

                  <div className="mb-2">
                    <span className="text-[#151e46] font-bold">Price</span>
                  </div>
                  <div className="flex items-center mb-2">
                    <input
                        type="number"
                        className="p-2 bg-[#fff7e1] border-2 border-gray-300 rounded-md focus:outline-none focus:border-[#40b06d] w-full"
                        value={price}
                        min="0"
                        step="0.01"
                        required={true}
                        onChange={(e) => setPrice(parseFloat(e.target.value) || 0)}
                    />
                  </div>
                  <div className="mb-2">
                    <span className="text-[#151e46] font-bold">Quantity (if needed)</span>
                  </div>
                  <div className="flex items-center mb-2">
                    <input
                        type="number"
                        className="p-2 bg-[#fff7e1] border-2 border-gray-300 rounded-md focus:outline-none focus:border-[#40b06d] w-full"
                        value={quantity}
                        min="0"
                        onChange={(e) => setQuantity(parseInt(e.target.value) || undefined)}
                    />
                  </div>
                </div>

                <div className="mb-4">
                  <label htmlFor="description" className="block text-[#151e46] font-bold mb-2">Description</label>
                  <textarea
                      id="description"
                      value={description}
                      required={true}
                      onChange={(e) => setDescription(e.target.value)}
                      className="p-2 bg-[#fff7e1] h-24 border-2 border-gray-300 rounded-md focus:outline-none focus:border-[#40b06d] w-full"
                  ></textarea>
                </div>

                <div className="grid grid-cols-2">
                  <div>
                    <div className="mb-2">
                      <span className="text-[#151e46] font-bold">Starting Time</span>
                    </div>
                    <div className="flex items-center mb-2">
                      <input
                          type="time"
                          className="p-2 bg-[#fff7e1] border-2 border-gray-300 rounded-md focus:outline-none focus:border-[#40b06d] w-full"
                          value={opensAt}
                          onChange={(e) => setOpensAt(e.target.value)}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="mb-2">
                      <span className="text-[#151e46] font-bold">Closing Time</span>
                    </div>
                    <div className="flex items-center mb-2">
                      <input
                          type="time"
                          className="p-2 bg-[#fff7e1] border-2 border-gray-300 rounded-md focus:outline-none focus:border-[#40b06d] w-full"
                          value={closesAt}
                          onChange={(e) => setClosesAt(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                <div className="flex items-end justify-between">
                  <div className="flex items-center">
                    {localStorage.getItem("branchID") !== "0" && (
                        <>
                          <img
                              alt="image"
                              onClick={(event) => {
                                dispatch(updateItemAvailability(localStorage.getItem("branchID"), item.id, !availability));
                                setAvailability(!availability);
                              }}
                              src={availability ? "/toggle-on-svgrepo-com.svg" : "/toggle-off-svgrepo-com.svg"}
                              className="w-12 h-12 mr-2"
                          />
                          <span className="text-[#151e46] text-sm">{availability ? "Available" : "Unavailable"}</span>
                        </>
                    )}
                  </div>
                  <div className="flex items-center">
                    <button
                        type="submit"
                        className="bg-[#40b06d] text-white py-2 px-4 rounded-md mr-2"
                        disabled={loading}
                    >
                      {loading ? "Saving..." : "Save"}
                    </button>
                  </div>
                </div>
              </form>
            </div>

            <div className="mt-8">
              <h3 className="text-2xl font-bold text-[#151e46]">Recommended Items</h3>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-4">
                {recommendedItems.map((recommendedItem) => (
                    <motion.div
                        key={recommendedItem.id}
                        initial={{opacity: 0, y: 20}}
                        animate={{opacity: 1, y: 0}}
                        transition={{duration: 0.5}}
                        className="bg-[#fff7e1] p-4 rounded-lg shadow-md relative"
                    >
                      {/* Item Image */}
                      <img
                          src={recommendedItem.image_url}
                          alt={recommendedItem.name}
                          className="w-full h-32 object-cover rounded-lg"
                      />

                      {/* Item Name and Description */}
                      <h4 className="text-lg font-semibold mt-2">{recommendedItem.name}</h4>
                      <p className="text-sm text-gray-600">{recommendedItem.description}</p>
                      {/* Delete Icon at Bottom-Right Corner */}
                      <button
                          onClick={(e) => handleRecommendationsSubmit(e, undefined, recommendedItem.id)}
                          className="absolute bottom-4 right-2 p-2 text-red-600 hover:text-red-800"
                      >
                        <FaTrash className="text-2xl text-[#F0532F]"/>
                      </button>
                    </motion.div>
                ))}
              </div>
            </div>

            <div className="mt-8">
              <h3 className="text-2xl font-bold text-[#151e46]">Add Recommended Item</h3>
              <div className="mt-4">
                <select
                    className="p-2 bg-[#fff7e1] border-2 border-gray-300 rounded-md focus:outline-none focus:border-[#40b06d] w-full"
                    onChange={(e) => {
                      const selectedId = parseInt(e.target.value);
                      if (selectedId && !item.recommended_ids?.includes(selectedId)) {
                        const updatedRecommendedIds = [...(item.recommended_ids || []), selectedId];
                        setItem({...item, recommended_ids: updatedRecommendedIds});
                        handleRecommendationsSubmit(e, selectedId);
                      }
                    }}
                >
                  <option value="">Select an item to recommend</option>
                  {menuList
                      ?.flatMap((category) => category.menuItems) // Flatten the menuItems across all categories
                      ?.filter((menuItem) => !item?.recommended_ids?.includes(menuItem.id)) // Exclude recommended_ids
                      ?.map((menuItem) => (
                          <option key={menuItem.id} value={menuItem.id}>
                            {menuItem.name}
                          </option>
                      ))}
                </select>
              </div>
            </div>



            <hr/>

            <CustomizationOptions
                data={item.customization_options}
                customizationOption={customizationOption}
                setCustomizationOption={setCustomizationOption}
                customizationID={customizationID}
                setCustomizationID={setCustomizationID}
                item={item}
                categoryName={item.categoryName}
            />
          </motion.div>
        </div>
        <AnimatePresence>
          {error && (
              <motion.div
                  initial={{opacity: 0}}
                  animate={{opacity: 1}}
                  exit={{opacity: 0}}
                  transition={{duration: 0.5}}
              >
                <BottomCallout message={error} variant="error"/>
              </motion.div>
          )}
          {showCallout && (
              <motion.div
                  initial={{opacity: 0}}
                  animate={{opacity: 1}}
                  exit={{opacity: 0}}
                  transition={{duration: 0.5}}
              >
                <BottomCallout message="Menu updated successfully" variant="success"/>
              </motion.div>
          )}
        </AnimatePresence>
      </div>
  );
};

export default MenuItemEditView;
