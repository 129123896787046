import React, {useEffect, useState} from 'react';
import Sidebar from "../../components/Sidebar";
import {useDispatch, useSelector} from "react-redux";
import {
    getOrderAction,
    listTablesAction,
    updateOrderAction,
    getReceiptViewAction
} from "../../redux/order/actions";
import {Link, useParams} from "react-router-dom";
import {listMenuAction} from "../../redux/menu/actions";
import AddNewItemModal from "./components/AddItemModal";
import UpdateItemModal from "./components/UpdateItemModal";
import BottomCallout from "../../components/BottomCallout";
import {FaEdit} from 'react-icons/fa';
import ConfirmationModal from "../../components/ConfirmationModal";
import ReceiptModal from './components/ReceiptModal';
import {getReceiptViewReducer} from "../../redux/order/reducers";
import {BASE_URL} from '../../config';
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { motion, AnimatePresence } from "framer-motion";

const OrderPage = () => {
    const [openIndex, setOpenIndex] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isReceiptModalOpen, setIsReceiptModalOpen] = useState(false);
    const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [selectedTable, setSelectedTable] = useState(null);
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const [pendingUpdate, setPendingUpdate] = useState(null);
    const [status, setStatus] = useState('');
    const [updateTable, setUpdateTable] = useState(false)
    const [updateStatus, setUpdateStatus] = useState(false)
    const [itemStatuses, setItemStatuses] = useState({});
    const [orderItemPendingUpdate, setOrderItemPendingUpdate] = useState(null);
    const [trigger, setTrigger] = useState(0);

    const dispatch = useDispatch();
    const getOrderReducer = useSelector(state => state.getOrderReducer.order);
    const menuList = useSelector(state => state.listMenuReducer.menu);
    const {error, loading, success} = useSelector(state => state.updateOrderReducer);
    const tableList = useSelector(state => state.listTablesReducer.tables);
    const receipt = useSelector(state => state.getReceiptReducer.receipt);
    const params = useParams();

    useEffect(() => {
        dispatch(getOrderAction(params.id));
        dispatch(listMenuAction(getOrderReducer?.order?.branchID));
        dispatch(getReceiptViewAction(params.id))
        dispatch(listTablesAction(localStorage.getItem("branchID") !== "0" && `branch_id=${localStorage.getItem("branchID")}`));
    }, [dispatch, params.id, loading, trigger]);

    useEffect(() => {
        if (success) handleCloseModal()
    }, [success])

    useEffect(() => {
        const eventSource = new EventSource(`${BASE_URL}/orders/events`, {withCredentials: true});

        eventSource.addEventListener("OrdersUpdated", () => {
            setTimeout(() => {
                setTrigger((prev) => prev + 1); // SET TRIGGER AFTER 2 SECS TO WAIT FOR COMMIT
            }, 2000);
        });

        eventSource.addEventListener("connected", (event) => {
            console.log("Connected to SSE:", event.data);
        });

        // Cleanup on component unmount
        return () => {
            eventSource.close();
        };
    }, []);

    useEffect(() => {
        if (getOrderReducer) {
            setSelectedTable(getOrderReducer.order.tableID);
            setStatus(getOrderReducer.order.statusName);
            const initialStatuses = {};
            getOrderReducer.items.forEach(item => {
                initialStatuses[item.orderItemID] = item.statusName
            });
            setItemStatuses(initialStatuses);
        }
    }, [getOrderReducer]);

    const toggleAccordion = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    const handleNewItemClick = () => {
        setIsModalOpen(true);
    };

    const handleOpenReceipt = () => {
        setIsReceiptModalOpen(true);
    }

    const handleCloseReceipt = () => {
        setIsReceiptModalOpen(false);
    }

    const handleUpdateItemClick = (item) => {
        setSelectedItem(item);
        setIsUpdateModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setIsUpdateModalOpen(false);
        setSelectedItem(null);
    };

    const handleTableChange = (event) => {
        const selectedTableID = event.target.value;
        setPendingUpdate({tableID: selectedTableID, status}); // Set pending update
        setIsConfirmationOpen(true); // Open confirmation modal
        setUpdateTable(true)
    };

    const handleStatusChange = (event) => {
        setPendingUpdate({tableID: selectedTable, status: event.target.value}); // Set pending update
        setIsConfirmationOpen(true); // Open confirmation modal
        setUpdateStatus(true)
    };

    const handleOrderItemStatusChange = (event, itemID) => {
        const updatedStatus = event.target.value;
        setOrderItemPendingUpdate({orderItemID: itemID, orderItemStatus: updatedStatus});
        setIsConfirmationOpen(true);
    };


    const handleUpdateOrder = () => {
        if (pendingUpdate) {
            const body = {
                tableID: updateTable && pendingUpdate.tableID ? parseInt(pendingUpdate.tableID) : undefined,
                status: updateStatus && pendingUpdate.status,
            };
            dispatch(updateOrderAction(params.id, body));
            setPendingUpdate(null);
        }
        if (orderItemPendingUpdate) {
            dispatch(updateOrderAction(params.id, {
                    orderItemStatus: orderItemPendingUpdate.orderItemStatus,
                    orderItemID: orderItemPendingUpdate.orderItemID
                }
            ));
            setOrderItemPendingUpdate(null);
        }
        setUpdateStatus(false)
        setUpdateTable(false)
        setIsConfirmationOpen(false);
    };

    useEffect(() => {
        if (success) {
            setIsModalOpen(false);
        }
    }, [success]);

    const handleCancelUpdate = () => {
        setPendingUpdate(null);
        setOrderItemPendingUpdate(null);
        setUpdateStatus(false)
        setUpdateTable(false)
        setIsConfirmationOpen(false);
    };

    return (
        <div className="flex w-full bg-[#FFF7E1] min-h-screen">
            <Sidebar />
            <div className="ml-[100px] w-full flex-1 mx-auto bg-[#FFF7E1] p-6">
                {/* Header Section */}
                <header className="mb-8">
                    <h1 className="text-5xl font-bold text-[#151E46] mb-4">
                        Order #{getOrderReducer?.order?.orderID || <Skeleton width={100} />}
                    </h1>
                    <hr className="border-t border-[#40B06D]/20 mb-6" />
                    <div className="grid grid-cols-1 md:grid-cols-5 gap-6 mb-6">
                        {getOrderReducer?.order?.typeName === "dine_in" && (
                            <div>
                                <p className="text-xl font-semibold text-[#40B06D] mb-2">Table #</p>
                                <select
                                    className="w-full p-2 text-lg text-[#151E46] bg-[#FFF7E1] border border-[#40B06D] rounded-lg focus:ring-[#40B06D] focus:border-[#40B06D] transition-colors"
                                    value={selectedTable}
                                    onChange={handleTableChange}
                                >
                                    {tableList?.map((table) => (
                                        <option key={table.id} value={table.id}>
                                            {table.tableNumber}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        )}
                        <div>
                            <p className="text-xl font-semibold text-[#40B06D] mb-2">Status</p>
                            <select
                                className="w-full p-2 text-lg text-[#151E46] bg-[#FFF7E1] border border-[#40B06D] rounded-lg focus:ring-[#40B06D] focus:border-[#40B06D] transition-colors"
                                value={status}
                                onChange={handleStatusChange}
                            >
                                <option value="in_progress">In Progress</option>
                                <option value="completed">Completed</option>
                            </select>
                        </div>
                        <div>
                            <p className="text-xl font-semibold text-[#40B06D] mb-2">Time</p>
                            {getOrderReducer?.order ? (
                                <>
                                    <p className="text-lg text-[#151E46]">
                                        {new Date(getOrderReducer?.order?.createdAt).toLocaleTimeString()}
                                    </p>
                                    <p className="text-lg text-[#151E46]">
                                        {new Date(getOrderReducer?.order?.createdAt).toLocaleDateString()}
                                    </p>
                                </>
                            ) : (
                                <Skeleton count={2} />
                            )}
                        </div>
                        <div>
                            <p className="text-xl font-semibold text-[#40B06D] mb-2">Contact</p>
                            <p className="text-lg text-[#151E46]">
                                {getOrderReducer?.order?.customerFirstName}{" "}
                                {getOrderReducer?.order?.customerLastName}
                            </p>
                            <p className="text-lg text-[#151E46]">
                                {getOrderReducer?.order?.customerPhoneNumber}
                            </p>
                        </div>
                        <div>
                            <p className="text-xl font-semibold text-[#40B06D] mb-2">Type</p>
                            <p className="text-lg text-[#151E46]">
                                {getOrderReducer?.order?.typeDisplayName}
                            </p>
                        </div>
                    </div>
                    <div className="flex space-x-4">
                        <button
                            className="px-6 py-3 bg-[#40B06D] text-white font-semibold rounded-lg hover:bg-[#40B06D]/90 transition-colors"
                            onClick={handleNewItemClick}
                        >
                            New Item
                        </button>
                        <button
                            className={`px-6 py-3 font-semibold rounded-lg transition-colors ${
                                receipt == null
                                    ? "bg-gray-400 text-gray-200 cursor-not-allowed"
                                    : "bg-[#40B06D] text-white hover:bg-[#40B06D]/90"
                            }`}
                            onClick={handleOpenReceipt}
                            disabled={receipt == null}
                        >
                            View Receipt
                        </button>

                        <button
                            className={`px-6 py-3 font-semibold rounded-lg transition-colors ${
                                receipt == null
                                    ? "bg-gray-400 text-gray-200 cursor-not-allowed"
                                    : "bg-[#40B06D] text-white hover:bg-[#40B06D]/90"
                            }`}
                            disabled={receipt == null}
                        >
                            <Link
                                to={getOrderReducer?.order?.orderID ? `/transactions?order_id=${getOrderReducer.order.orderID}` : "#"}
                                className={`mt-4 px-4 py-2 font-semibold rounded-md ${
                                    getOrderReducer?.order?.orderID ? 'bg-[#40b06d] text-white' : 'bg-gray-400 text-gray-200 cursor-not-allowed'
                                }`}
                            >
                                View Transactions
                            </Link>
                        </button>
                    </div>
                </header>

                {/* Order Items Section */}
                <div className="space-y-4">
                    {getOrderReducer?.items?.map((item, index) => (
                        <motion.div
                            key={item.orderItemID}
                            className="bg-[#FFF7E1] p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow border border-[#40B06D]/20"
                            initial={{ opacity: 0, y: 10 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -10 }}
                            onClick={() => toggleAccordion(index)}
                        >
                            <div className="flex justify-between items-center">
                                <div className="flex items-center space-x-4">
                                    <p className="text-xl font-semibold text-[#151E46]">
                                        {item.quantity}x {item.name}
                                    </p>
                                    <button
                                        onClick={() => handleUpdateItemClick(item)}
                                        className={`text-gray-600 hover:text-[#40B06D] transition-colors ${
                                            item.statusName === "cancelled" ? "hidden" : ""
                                        }`}
                                    >
                                        <FaEdit />
                                    </button>
                                </div>
                                <div className="flex items-center space-x-4">
                                    <p
                                        className={`text-xl font-semibold ${
                                            itemStatuses[item.orderItemID] === "cancelled"
                                                ? "text-red-600"
                                                : "text-[#40B06D]"
                                        }`}
                                    >
                                        {item.total} EGP
                                    </p>
                                    <select
                                        className={`text-lg font-semibold bg-transparent border border-[#40B06D] rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-[#40B06D] transition-colors ${
                                            itemStatuses[item.orderItemID] === "cancelled"
                                                ? "bg-red-100 text-red-600 cursor-not-allowed"
                                                : "text-[#151E46]"
                                        }`}
                                        value={itemStatuses[item.orderItemID]}
                                        onChange={(e) => handleOrderItemStatusChange(e, item.orderItemID)}
                                        disabled={itemStatuses[item.orderItemID] === "cancelled"}
                                    >
                                        <option value="pending">Pending</option>
                                        <option value="cancelled">Cancelled</option>
                                        <option value="preparing">Preparing</option>
                                        <option value="served">Served</option>
                                    </select>
                                </div>
                            </div>
                            {openIndex === index && (
                                <motion.div
                                    className="mt-4 space-y-4"
                                    initial={{ opacity: 0, height: 0 }}
                                    animate={{ opacity: 1, height: "auto" }}
                                    exit={{ opacity: 0, height: 0 }}
                                >
                                    {/* Answers Section */}
                                    <div className="p-4 bg-[#FFF7E1] rounded-lg border border-[#40B06D]/20">
                                        <h3 className="text-xl font-bold text-[#151E46] mb-2">Answers</h3>
                                        {item?.answers &&
                                            item?.answers?.map((answerSet, ansIndex) =>
                                                    answerSet?.map((answer, innerIndex) => (
                                                        <div key={`${ansIndex}-${innerIndex}`} className="mb-2">
                                                            <div className="flex items-start">
                              <span className="font-semibold text-lg text-[#151E46] mr-2">
                                {answer.question}:
                              </span>
                                                                <span className="text-lg text-[#6B7885]">
                                {answer?.answers
                                    ?.map((item, index) => {
                                        if (typeof item === "string") {
                                            return <span key={index}>{item}</span>;
                                        } else if (
                                            typeof item === "object" &&
                                            item.choiceName &&
                                            item.quantity > 0
                                        ) {
                                            return (
                                                <span key={index}>
                                          {item.choiceName} (x{item.quantity})
                                        </span>
                                            );
                                        }
                                        return null;
                                    })
                                    ?.filter(Boolean)
                                    .reduce((prev, curr) => [prev, ", ", curr])}
                              </span>
                                                            </div>
                                                        </div>
                                                    ))
                                            )}
                                    </div>

                                    {/* Special Requests Section */}
                                    {item.specialRequest && (
                                        <div className="p-4 bg-[#FFF7E1] rounded-lg border border-[#40B06D]/20">
                                            <h3 className="text-xl font-bold text-[#151E46] mb-2">
                                                Special Requests
                                            </h3>
                                            <p className="text-lg text-[#6B7885]">{item.specialRequest}</p>
                                        </div>
                                    )}
                                </motion.div>
                            )}
                        </motion.div>
                    ))}
                </div>

                {/* Modals */}
                {isModalOpen && <AddNewItemModal menuList={menuList} onClose={handleCloseModal} />}
                {isUpdateModalOpen && selectedItem && (
                    <UpdateItemModal
                        itemData={selectedItem}
                        menuList={menuList}
                        onClose={handleCloseModal}
                    />
                )}
                {isReceiptModalOpen && (
                    <ReceiptModal receipt={receipt} onClose={handleCloseReceipt} />
                )}
                {isConfirmationOpen && (
                    <ConfirmationModal
                        message="Are you sure you want to update the order?"
                        onConfirm={handleUpdateOrder}
                        onCancel={handleCancelUpdate}
                    />
                )}
                {error && <BottomCallout message={error} variant={"error"} />}
            </div>
        </div>
    );
};

export default OrderPage;
