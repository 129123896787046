import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BottomCallout from "../../../components/BottomCallout";
import {
    updateMenuAction,
    addMenuItemAction,
    listMenuAction,
} from "../../../redux/menu/actions";
import { motion, AnimatePresence } from "framer-motion";
import { FaTimes } from "react-icons/fa";

const NewItemModal = ({ isOpen, onClose, categoryNames }) => {
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [price, setPrice] = useState("");
    const [categoryName, setCategoryName] = useState("");
    const [newCategoryName, setNewCategoryName] = useState("");
    const [newCategory, setNewCategory] = useState(false);
    const [imageFile, setImageFile] = useState(null);
    const [opensAt, setOpensAt] = useState(null);
    const [closesAt, setClosesAt] = useState(null);

    const dispatch = useDispatch();
    const menuUpdate = useSelector((state) => state.menuUpdateReducer);
    const menuAdd = useSelector((state) => state.menuAddReducer);
    const { loading, error, success } = menuUpdate;
    const { loading: loading2, error: error2, success: success2 } = menuAdd;
    const [submit, setSubmit] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        let params;
        if (newCategory) {
            params = {
                name: newCategoryName,
                items: [
                    {
                        name,
                        description,
                        price,
                        opensAt,
                        closesAt,
                    },
                ],
            };
        } else {
            params = {
                name: categoryName,
                item: {
                    name,
                    description,
                    price,
                    opensAt,
                    closesAt,
                },
            };
        }

        if (newCategory) {
            dispatch(addMenuItemAction(params, imageFile));
        } else {
            const categoryId = categoryNames.find(
                (category) => category.name === categoryName
            )?.id;
            dispatch(updateMenuAction(categoryId, params, imageFile));
        }

        setSubmit(true);
    };

    useEffect(() => {
        if (!submit) return;

        if (
            (!error && success && !newCategory) ||
            (!error2 && success2 && newCategory)
        ) {
            // Dispatch list menu after successful addition
            dispatch(listMenuAction());
            onClose(); // Close the modal after the item is added
        }
    }, [error, error2, success, success2, submit, dispatch, onClose]);


    if (!isOpen) return null;
    return (
        <AnimatePresence>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
            >
                <motion.div
                    initial={{ scale: 0.8 }}
                    animate={{ scale: 1 }}
                    exit={{ scale: 0.8 }}
                    className="bg-[#fff7e1] p-6 rounded-lg w-full max-w-lg shadow-lg relative"
                >
                    {/* Close Button */}
                    <button
                        onClick={onClose}
                        className="absolute top-4 right-4 text-[#151e46] hover:text-[#40b06d] transition-all duration-200"
                    >
                        <FaTimes className="text-2xl" />
                    </button>

                    <h2 className="text-2xl font-bold mb-4 text-[#151e46]">Add New Item</h2>
                    <form onSubmit={handleSubmit}>
                        {/* Category Name */}
                        <div className="mb-4">
                            <label className="block text-[#151e46] font-medium mb-1">
                                Category Name *
                            </label>
                            <select
                                className="block w-full mt-1 p-2 border border-[#151e46] rounded-md bg-transparent text-[#151e46] focus:outline-none focus:ring-2 focus:ring-[#40b06d]"
                                value={categoryName}
                                onChange={(e) => {
                                    setCategoryName(e.target.value);
                                    if (e.target.value === "secretToken") {
                                        setNewCategory(true);
                                    } else {
                                        setNewCategory(false);
                                    }
                                }}
                                required
                            >
                                <option value="" disabled>
                                    Select a category
                                </option>
                                {categoryNames.map((category) => (
                                    <option key={category.id} value={category.name}>
                                        {category.name}
                                    </option>
                                ))}
                                <option value="secretToken">Add New Category</option>
                            </select>
                            {newCategory && (
                                <input
                                    type="text"
                                    placeholder="New Category Name"
                                    className="block w-full mt-2 p-2 border border-[#151e46] rounded-md bg-transparent text-[#151e46] focus:outline-none focus:ring-2 focus:ring-[#40b06d]"
                                    value={newCategoryName}
                                    onChange={(e) => {
                                        setNewCategoryName(e.target.value);
                                        setNewCategory(true);
                                    }}
                                    required
                                />
                            )}
                        </div>

                        {/* Item Name */}
                        <div className="mb-4">
                            <label className="block text-[#151e46] font-medium mb-1">
                                Item Name *
                            </label>
                            <input
                                type="text"
                                className="block w-full mt-1 p-2 border border-[#151e46] rounded-md bg-transparent text-[#151e46] focus:outline-none focus:ring-2 focus:ring-[#40b06d]"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required
                            />
                        </div>

                        {/* Description */}
                        <div className="mb-4">
                            <label className="block text-[#151e46] font-medium mb-1">
                                Description *
                            </label>
                            <textarea
                                className="block w-full mt-1 p-2 border border-[#151e46] rounded-md bg-transparent text-[#151e46] focus:outline-none focus:ring-2 focus:ring-[#40b06d]"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                required
                            ></textarea>
                        </div>

                        {/* Price */}
                        <div className="mb-4">
                            <label className="block text-[#151e46] font-medium mb-1">
                                Price *
                            </label>
                            <input
                                type="number"
                                className="block w-full mt-1 p-2 border border-[#151e46] rounded-md bg-transparent text-[#151e46] focus:outline-none focus:ring-2 focus:ring-[#40b06d]"
                                value={price}
                                min="0"
                                step="0.01"
                                onChange={(e) => setPrice(e.target.value)}
                                required
                            />
                        </div>

                        {/* Starting Time */}
                        <div className="mb-4">
                            <label className="block text-[#151e46] font-medium mb-1">
                                Starting Time
                            </label>
                            <input
                                type="time"
                                className="block w-full mt-1 p-2 border border-[#151e46] rounded-md bg-transparent text-[#151e46] focus:outline-none focus:ring-2 focus:ring-[#40b06d]"
                                value={opensAt}
                                onChange={(e) => setOpensAt(e.target.value)}
                            />
                        </div>

                        {/* Closing Time */}
                        <div className="mb-4">
                            <label className="block text-[#151e46] font-medium mb-1">
                                Closing Time
                            </label>
                            <input
                                type="time"
                                className="block w-full mt-1 p-2 border border-[#151e46] rounded-md bg-transparent text-[#151e46] focus:outline-none focus:ring-2 focus:ring-[#40b06d]"
                                value={closesAt}
                                onChange={(e) => setClosesAt(e.target.value)}
                            />
                        </div>

                        {/* Image Upload */}
                        <div className="mb-6">
                            <label className="block text-[#151e46] font-medium mb-1">
                                Image *
                            </label>
                            <input
                                type="file"
                                className="block w-full mt-1 p-2 border border-[#151e46] rounded-md bg-transparent text-[#151e46] focus:outline-none focus:ring-2 focus:ring-[#40b06d]"
                                onChange={(e) => setImageFile(e.target.files[0])}
                                required
                            />
                        </div>

                        {/* Buttons */}
                        <div className="flex justify-end space-x-4">
                            <motion.button
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                                type="button"
                                className="bg-[#F0532F] text-white px-6 py-2 rounded-md hover:bg-[#e04a2a] transition-all duration-200"
                                onClick={onClose}
                            >
                                Cancel
                            </motion.button>
                            <motion.button
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                                type="submit"
                                className="bg-[#40b06d] text-white px-6 py-2 rounded-md hover:bg-[#3aa363] transition-all duration-200"
                                disabled={loading || loading2}
                            >
                                {loading || loading2 ? "Saving..." : "Save"}
                            </motion.button>
                        </div>
                    </form>

                    {/* Error and Success Messages */}
                    <AnimatePresence>
                        {(error || error2) && (
                            <motion.div
                                initial={{ opacity: 0, y: -20 }}
                                animate={{ opacity: 1, y: 0 }}
                                exit={{ opacity: 0, y: -20 }}
                                className="mt-4"
                            >
                                <BottomCallout message={error || error2} variant="error" />
                            </motion.div>
                        )}
                        {(success || success2) && (
                            <motion.div
                                initial={{ opacity: 0, y: -20 }}
                                animate={{ opacity: 1, y: 0 }}
                                exit={{ opacity: 0, y: -20 }}
                                className="mt-4"
                            >
                                <BottomCallout
                                    message="Item added successfully!"
                                    variant="success"
                                />
                            </motion.div>
                        )}
                    </AnimatePresence>
                </motion.div>
            </motion.div>
        </AnimatePresence>
    );
};

export default NewItemModal;