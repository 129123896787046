import React from 'react';
import { Dialog } from '@headlessui/react';
import { motion, AnimatePresence } from 'framer-motion';

const ReceiptModal = ({ receipt, onClose }) => {
    return (
        <AnimatePresence>
            <Dialog open={true} onClose={onClose} className="fixed z-10 inset-0 overflow-y-auto">
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className="flex items-center justify-center min-h-screen bg-black bg-opacity-50"
                >
                    <motion.div
                        initial={{ scale: 0.9, y: 20 }}
                        animate={{ scale: 1, y: 0 }}
                        exit={{ scale: 0.9, y: 20 }}
                        className="bg-[#FFF7E1] max-w-3xl mx-auto p-8 rounded-xl shadow-2xl my-6"
                        id="invoice"
                    >
                        <Dialog.Title className="text-3xl font-bold text-[#151E46] mb-6">Order Receipt</Dialog.Title>

                        {/* Client info */}
                        <div className="grid grid-cols-2 items-center mt-8">
                            <div>
                                <p className="text-lg font-semibold text-[#151E46]">Order ID: <span className="text-gray-600">{receipt?.orderID}</span></p>
                                <p className="text-lg font-semibold text-[#151E46]">
                                    Order Date: <span className="text-gray-600">
                                        {receipt && new Date(receipt?.createdAt).toLocaleDateString("en-US", {
                                            year: "numeric",
                                            month: "long",
                                            day: "numeric"
                                        })}{" "}
                                    {receipt && new Date(receipt?.createdAt).toLocaleTimeString("en-US", {
                                        hour: "2-digit",
                                        minute: "2-digit",
                                        second: "2-digit",
                                        hour12: true
                                    })}
                                    </span>
                                </p>
                            </div>
                        </div>

                        <div className="-mx-4 mt-8 flow-root sm:mx-0">
                            <table className="min-w-full">
                                <colgroup>
                                    <col className="w-full sm:w-1/2" />
                                    <col className="sm:w-1/6" />
                                    <col className="sm:w-1/6" />
                                    <col className="sm:w-1/6" />
                                </colgroup>
                                <thead className="border-b border-gray-300 text-gray-900">
                                <tr>
                                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">Items</th>
                                    <th scope="col" className="hidden px-3 py-3.5 text-right text-sm font-semibold text-gray-900 sm:table-cell">Quantity</th>
                                    <th scope="col" className="hidden px-3 py-3.5 text-right text-sm font-semibold text-gray-900 sm:table-cell">Price</th>
                                    <th scope="col" className="py-3.5 pl-3 pr-4 text-right text-sm font-semibold text-gray-900 sm:pr-0">Amount</th>
                                </tr>
                                </thead>
                                <tbody>
                                {receipt?.items.map((item, index) => (
                                    <tr key={index} className="border-b border-gray-200">
                                        {/* Item Name */}
                                        <td className="max-w-0 py-5 pl-4 pr-3 text-sm sm:pl-0">
                                            <div className="font-medium text-gray-900">{item.name}</div>
                                            <div className="mt-1 truncate text-gray-500">
                                                {item.answers[0]?.map((answerItem, i) => (
                                                    <div key={i}>
                                                        <strong>{answerItem?.question}:</strong>  {Array.isArray(answerItem?.answers) && answerItem.answers.length > 0 ? (
                                                        typeof answerItem.answers[0] === "string" ? (
                                                            answerItem.answers.join(", ")
                                                        ) : (
                                                            answerItem.answers
                                                                .filter((ans) => ans.quantity > 0)
                                                                .map((ans) => `${ans.choiceName} (x${ans.quantity})`)
                                                                .join(", ")
                                                        )
                                                    ) : ""}
                                                    </div>
                                                ))}
                                            </div>
                                        </td>

                                        {/* Quantity */}
                                        <td className="hidden px-3 py-5 text-right text-sm text-gray-500 sm:table-cell">
                                            {item.total_quantity}
                                        </td>

                                        {/* Price */}
                                        <td className="hidden px-3 py-5 text-right text-sm text-gray-500 sm:table-cell">
                                            {item.price}
                                        </td>

                                        {/* Total Amount */}
                                        <td className="py-5 pl-3 pr-4 text-right text-sm text-gray-500 sm:pr-0">
                                            {item.total_price}
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                                <tfoot>
                                <tr>
                                    <th scope="row" colSpan="3" className="hidden pl-4 pr-3 pt-6 text-right text-sm font-normal text-gray-500 sm:table-cell sm:pl-0">Subtotal</th>
                                    <th scope="row" className="pl-6 pr-3 pt-6 text-left text-sm font-normal text-gray-500 sm:hidden">Subtotal</th>
                                    <td className="pl-3 pr-6 pt-6 text-right text-sm text-gray-500 sm:pr-0">EGP {receipt?.subtotal}</td>
                                </tr>
                                <tr>
                                    <th scope="row" colSpan="3" className="hidden pl-4 pr-3 pt-4 text-right text-sm font-normal text-gray-500 sm:table-cell sm:pl-0">Tax (14%)</th>
                                    <th scope="row" className="pl-6 pr-3 pt-4 text-left text-sm font-normal text-gray-500 sm:hidden">Tax</th>
                                    <td className="pl-3 pr-6 pt-4 text-right text-sm text-gray-500 sm:pr-0">EGP {receipt?.orderTaxes}</td>
                                </tr>
                                <tr>
                                    <th scope="row" colSpan="3" className="hidden pl-4 pr-3 pt-4 text-right text-sm font-normal text-gray-500 sm:table-cell sm:pl-0">Service (12%)</th>
                                    <th scope="row" className="pl-6 pr-3 pt-4 text-left text-sm font-normal text-gray-500 sm:hidden">Service</th>
                                    <td className="pl-3 pr-6 pt-4 text-right text-sm text-gray-500 sm:pr-0">EGP {receipt?.orderService}</td>
                                </tr>
                                <tr>
                                    <th scope="row" colSpan="3" className="hidden pl-4 pr-3 pt-4 text-right text-sm font-semibold text-gray-900 sm:table-cell sm:pl-0">Total</th>
                                    <th scope="row" className="pl-6 pr-3 pt-4 text-left text-sm font-semibold text-gray-900 sm:hidden">Total</th>
                                    <td className="pl-3 pr-4 pt-4 text-right text-sm font-semibold text-gray-900 sm:pr-0">EGP {receipt?.orderTotal}</td>
                                </tr>
                                </tfoot>
                            </table>
                        </div>
                        <div className="mt-8 flex justify-end">
                            <motion.button
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                                className="px-6 py-2 bg-[#F0532F] text-white font-semibold rounded-lg hover:bg-[#F0532F]/90 transition-colors"
                                onClick={onClose}
                            >
                                Close
                            </motion.button>
                        </div>
                        <div className="border-t-2 pt-4 text-xs text-[#FFF7E1] text-center mt-16">
                            ----------------------------------------------------------------------------------------------------------------------
                        </div>
                    </motion.div>
                </motion.div>
            </Dialog>
        </AnimatePresence>
    );
};

export default ReceiptModal;