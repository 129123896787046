import React from "react";
import { motion, AnimatePresence } from "framer-motion";

const ConfirmationModal = ({ message, onConfirm, onCancel }) => {
    return (
        <AnimatePresence>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="fixed inset-0 z-50 flex items-center justify-center bg-[#151E46]/80 backdrop-blur-sm"
            >
                <motion.div
                    initial={{ scale: 0.9, y: 20 }}
                    animate={{ scale: 1, y: 0 }}
                    exit={{ scale: 0.9, y: 20 }}
                    className="bg-[#FFF7E1] rounded-xl shadow-lg p-8 w-full max-w-md text-center"
                >
                    {/* Message */}
                    <p className="text-2xl font-semibold text-[#151E46] mb-6">{message}</p>

                    {/* Buttons */}
                    <div className="flex justify-center space-x-4">
                        <motion.button
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                            onClick={onCancel}
                            className="px-6 py-2 bg-[#F0532F] text-white font-semibold rounded-lg hover:bg-[#F0532F]/90 transition-colors"
                        >
                            Cancel
                        </motion.button>
                        <motion.button
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                            onClick={onConfirm}
                            className="px-6 py-2 bg-[#40B06D] text-white font-semibold rounded-lg hover:bg-[#40B06D]/90 transition-colors"
                        >
                            Confirm
                        </motion.button>
                    </div>
                </motion.div>
            </motion.div>
        </AnimatePresence>
    );
};

export default ConfirmationModal;